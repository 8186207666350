.assessment-page {
  .assessment-name {
    .cta-container {
      & { display: flex; align-items: center; gap: 20px; }
      .outline-btn { height: 48px; cursor: pointer; border-radius: 4px; border: 2px solid #122845; padding: 6px 20px; text-transform: uppercase; font-size: 14px; font-weight: 600; color: #122845; font-family: $fontmontserrat; }
      .fill-btn { height: 48px; text-transform: uppercase;  padding: 6px 20px; border: 2px solid #212A42; background-color: #212A42; color: #fff; border-radius: 4px; font-size: 15px; font-weight: 600; font-family: $fontmontserrat; }
      
    }
  }
  .parish-table {
    & { background-color: #fbf9f3; }
    .users-table-top {
      .back-arrow {
        & { display: flex; align-items: center; gap: 20px; }
        .dashboard-link { 
          a { color: #84161d; font-size: 18px; font-weight: 600; }
        }
      }
    }
    .delete-btn { color: #212121; padding: 0; width: auto; min-width: 20px; max-width: 100%; }
    span.new-tag { background: #84161d; margin-left: 10px; color: #fff; font-weight: 600; padding: 0 10px; border-radius: 30px; }
    .MuiBox-root { height: 76vh; width: 96%; margin: 16px auto }
    .MuiDataGrid-columnHeaderTitle { font-weight: 700; color: '#494949'; font-size: '16px'; }
    .MuiDataGrid-columnHeadersInner { background-color: '#f1f1f1'; width: '100%' }
    .MuiDataGrid-row { background-color: #fff; }
    .filter-container { 
      & { display: flex; align-items: center; gap: 16px; margin-bottom: 16px; }
      .search-bar {
        & { position: relative; align-self: center; width: 250px; }
        input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
        .search-field { background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; outline: none; border: 1px solid #CBCBCB; border-radius: 3px; font-family: $fontmontserrat; width: 250px; }
        .clear-search-icon { position: absolute; right: 8px; bottom: 10px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
      }
      .diocese-chip { 
        & { display: flex; align-items: center; gap: 12px; background-color: #9e9e9e;  border-radius: 50px; padding: 5px 12px; }
        p { margin: 0; padding: 0; font-size: 11px; color: #fff; } 
      }
      .create-new-parish-cta {
        & { height: 40px; background-color: #122845; padding: 15px 20px; border-radius: 4px; color: #fff; font-weight: 600; font-size: 16px; font-family: $fontmontserrat; }
      }
  
     }

  
    .custom-cell { text-decoration: underline; cursor: pointer; }
  
    .users-table-top {
      .back-arrow { 
        svg { cursor: pointer; }
      }
      .counts {
        & { display: flex;  gap: 48px;
          span { background-color: #ffffff; color: #000; padding: 10px 16px; border-radius: 4px; margin-left: 8px; } 
        }
      }
      .card-box-parish { display: flex; gap: 30px; flex-wrap: wrap; 
        .card-box-parish-statistics-cards { grid-template-columns: repeat(3, minmax(0, 1fr)); }
      }
     }
  
  }
}

.sync-page{
  & { background-color: #FBF9F3; padding: 24px; }
  .sync-header {
    & { display: flex; gap: 24px; align-items: center; background-color: #FBF9F3; padding: 0; }
    .header-text { font-size: 24px; font-family: $fontmontserrat; font-weight: 800; color: #57606F; }
    .header-btns-container { 
      & { margin-left: auto; display: flex; gap: 24px; }
      .outline-btn { height: 48px; border: 2px solid #212A42; border-radius: 4px; font-family: $fontLato; font-size: 14px; text-transform: uppercase; font-weight: 800; padding: 4px 24px; color: #212A42; }
      .fill-btn { height: 48px; border-radius: 4px; font-size: 15px; color: #fff; font-family: $fontLato; text-transform: uppercase; font-weight: 800; background-color: #212A42; padding: 4px 24px; }
    }
  }
  .body {
    & { display: flex; flex-direction: column; justify-content: space-between; gap: 24px; }
    .flex-row { 
      & { display: flex; justify-content: space-between; gap: 24px; align-items: center; }
      .flex-column {
        & { display: flex; flex-direction: column; flex: 1; }
        .fill-btn { margin-right: 12px; height: 32px; border-radius: 4px; font-size: 12px; color: #fff; font-family: $fontLato; text-transform: uppercase; font-weight: 800; background-color: #212A42; padding: 4px 12px; }
        .type-txt { color: #212121; font-size: 16px; font-family: $fontmontserrat; }
        .filter-container { 
          & { display: flex; align-items: center; gap: 16px; margin-top: 24px; }
          .search-bar {
            & { position: relative; align-self: center; width: 250px; }
            input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
            .search-field { background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; outline: none; border: 1px solid #CBCBCB; border-radius: 3px; font-family: $fontmontserrat; width: 250px; }
            .clear-search-icon { position: absolute; right: 8px; bottom: 10px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
          }
          .diocese-chip { 
            & { display: flex; align-items: center; gap: 12px; background-color: #9e9e9e;  border-radius: 50px; padding: 5px 12px; }
            p { margin: 0; padding: 0; font-size: 11px; color: #fff; } 
          }
          .create-new-parish-cta {
            & { height: 40px; background-color: #122845; padding: 15px 20px; border-radius: 4px; color: #fff; font-weight: 600; font-size: 16px; font-family: $fontmontserrat; }
          }
      
         }
      }
      .hori-line { flex: 1; color: #bc0909; height: 1px; margin: 0 12px; }
      .middle-txt { font-size: 20px; color: #212121; }
    }
    .sync-btn { margin: auto; width: 250px; height: 64px; border-radius: 4px; background-color: #212A42; color: #fff; font-size: 24px; font-family: $fontLato; text-transform: uppercase; font-weight: 800; }
    .sync-page-button { display: flex; justify-content: space-around; }
  }
}

.duplicate-page {
  & { background-color: #FBF9F3; padding: 24px; }
  .duplicate-header {
    & { display: flex; gap: 24px; align-items: center; background-color: #FBF9F3; padding: 0; }
    .header-text { font-size: 24px; font-family: $fontmontserrat; font-weight: 800; color: #57606F; }
    .header-btns-container { 
      & { margin-left: auto; display: flex; gap: 24px; }
      .outline-btn { height: 40px; border: 2px solid #212A42; border-radius: 4px; font-family: $fontLato; font-size: 16px; text-transform: uppercase; font-weight: 800; padding: 4px 24px; color: #212A42; }
      .fill-btn { height: 40px; border-radius: 4px; font-size: 16px; color: #fff; font-family: $fontLato; text-transform: uppercase; font-weight: 800; background-color: #212A42; padding: 4px 24px; }
    }
  }
  .body {
    & { display: flex; flex-direction: column; gap: 24px; align-items: start; justify-content: start; }
    .body-header-txt { font-size: 20px; color: #212121; font-weight: 600; font-family: $fontmontserrat; }
    .table-cell { color: #0F131D; font-size: 14px; font-family: $fontmontserrat; font-weight: 600; }
    .btn-container { 
      & { display: flex; justify-content: center; align-items: center; gap: 24px; }
      .fill-btn {  margin-right: 12px; height: 40px; border-radius: 4px; font-size: 14px; color: #fff; font-family: $fontLato; text-transform: uppercase; font-weight: 800; background-color: #212A42; padding: 4px 24px;  }
      .outline-btn {  border: 2px solid #212A42; border-radius: 4px; font-family: $fontLato; font-size: 16px; text-transform: uppercase; font-weight: 800; padding: 9px 24px; color: #212A42;  }
     }
  }
}

.parish-insights-page {
  & {  background-color: #FBF9F3; padding: 24px; }
  .insights-header {
    & { display: flex; gap: 24px; align-items: center; }
    .header-text { font-size: 24px; font-family: $fontmontserrat; font-weight: 800; color: #57606F; }
    .header-btns-container { 
      & { margin-left: auto; display: flex; gap: 24px; }
      .outline-btn { height: 40px; border: 2px solid #212A42; border-radius: 4px; font-family: $fontLato; font-size: 16px; text-transform: uppercase; font-weight: 800; padding: 4px 24px; color: #212A42; }
      .fill-btn { height: 40px; border-radius: 4px; font-size: 16px; color: #fff; font-family: $fontLato; text-transform: uppercase; font-weight: 800; background-color: #212A42; padding: 4px 24px; }
    }
  }
}

@media screen and (max-width:1290px) {
  .sync-page .sync-header .header-btns-container .fill-btn, .sync-page .sync-header .header-btns-container .outline-btn, .assessment-page .assessment-name .cta-container .outline-btn, .assessment-page .assessment-name .cta-container .fill-btn { font-size: 10px; }
}