/* You can add global styles to this file, and also import other style files */

// fonts
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800;900&display=swap');
$mainfont : 'Nunito', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900&display=swap');
$mulish : 'Mulish', sans-serif;
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@font-face {
  font-family: "Butler";
  src: url("./assets/fonts/Butler.woff2") format("woff2"),
  url("./assets/fonts/Butler.woff") format("woff");
  //  url("./assets/fonts/Butler-Bold.woff2")
}

$fontmontserrat: 'Montserrat', sans-serif;
$fontbutler: 'Butler', sans-serif;
$fontLato: 'Lato', sans-serif;

$bg-failure: #eb4d4b;
$bg-success: #6ab04c;
$bg-pending: rgba(189,100,0,0.6);

// Theme Colours
$themecolor1: #e9eaee; // header
$themeaccentcolor1: #814495;
$themecolor2: #f5f5f5; // background
$themecolor3: #dfe4ea; // pagetitle
$themecolor4: #ffffff; // table-container
$themecolor5: #535c68; // menu

$menudividerlight: #f1f2f6;
$menudividerdark: #ced6e0;
$submenudividerlight: #8f51a4;
$submenudividerdark: #6b377c;

$buttoncolor1: #eb4d4b;
$buttonaccentcolor1: #ff7979;
$buttoncolor2: #f1f2f6;

$titlecolor1: #57606f;
$titlecolor2: #227093;

// dark theme
// $themecolor1: #aaa69d;
// $themecolor2: #f7f1e3;

@import './Pages/AssessmentUsers/AssessmentPage/AssessmentPage.scss';
@import './Pages/AssessmentUsers/AssessmentUsers/AssessmentUsers.scss';
@import './Pages/AssessmentParish/AssessmentParishInsights/AssessmentParishInsights.scss';
@import './Pages/AssessmentParish/SalesforceAuthenticate/SalesforceAuthenticate.scss';
@import "./Pages/QuadrantFlow/QuadrantFlow.scss";

// Global styles
html {height: 100%;}
body {margin: 0px !important; font-family: $mainfont, sans-serif;}
h1 { font-size: 26px; font-weight: 700; color: $titlecolor1; }

// Miscellaneous
.toolbarspacer {flex: 1 1 auto;}
.clear {clear: both;}
.actions { & { display: flex; justify-content: center; align-items: center; }
  .MuiSvgIcon-root { color: #454545; cursor: pointer; margin: 0 5px; }
}
.url { color: #343333; }

* [contentEditable="true"]:focus { outline: 0px ; }

// Button Styles
.redbtn { background: $buttoncolor1;color: #fff; }
.redbtn:hover { color: #fff;background: $buttonaccentcolor1; }
.greybtn { background: $buttoncolor2;color: $titlecolor1; }
.greybtn:hover { color: $titlecolor1; }
.black-btn { background-color: #122845 !important; color: #FFFFFF !important; font-weight: 600 !important; font-family: $fontmontserrat !important; }
.filterbtn { background: transparent !important;color: $titlecolor1; }
.green-btn { font-family: $fontmontserrat !important; background-color: #00a5b8 !important; color: $themecolor2 !important; font-weight: 600 !important; padding: 5px 10px !important; }
.MuiButton-root.gray-btn { & { font-family: $fontmontserrat; background-color: #e0e0e0; color: #343333; margin: 15px auto; padding: 5px 10px; font-weight: 600; }
  &:hover { background-color: #cecece; }
}
.MuiButton-root.salesforce-btn { background-color: #0D9DDA; color: #FFF; font-weight: 600; font-family: $fontmontserrat; margin: 0 0 0 auto; padding: 7px 14px; text-transform: capitalize; font-size: 16px; align-self: flex-start; }
.MuiButton-root.salesforce-btn:hover { background-color: #3baed3; color: #FFF; }
.duration .mce-content-body p { margin: 0 auto; }

// Remove browser unwanted styles
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px $themecolor2 inset !important;
}

.MuiChip-label { font-family: $mainfont; text-transform: uppercase; font-size: 10px; font-weight: 700; }


.vertical-layout, div.defaultLightbox, div.login, div.login > form  { display: flex; flex: 1; flex-direction: column; }

.horizontal-layout, div.main { display: flex; flex: 1; flex-direction: row; }

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar { background-color: $themecolor1 !important; }
.mat-ink-bar { height: 4px; }
.mat-tab-label-content { text-transform: uppercase;font-family: $mainfont; }
.mat-tab-labels { background: #fff; }

// Show Hide Classes 
.menu.hide-sidebar-menu { left: -300px; }
.menu.show-sidebar-menu { left: 0px; }
.bottom-links.hide-sidebar-bottom-links { left: -300px; }
.bottom-links.show-sidebar-bottom-links { left: 0px; }
.main.show-sidebar-shrink-main { max-width: calc(100vw - 300px); margin-left: 300px; }
.main.hide-sidebar-expand-main { max-width: 100%; margin-left: 0px; }

.settings-drawer { 
  .MuiPaper-root {
    & { width: 350px; padding-bottom: 110px; }
  }
  .heading-close-container {
    & { display: flex; align-items: center; gap: 12px; padding: 24px; }
    h1 {
      & { font-size: 27px; color: #000; margin: 0; }
      svg { font-size: 26px; cursor: pointer; }
    }
  }
  .settings-drawer-container {
    p { margin: 10px; }
    .title { font-size: 21px; font-weight: bold; }
    .client-name { font-size: 16px; }
    .assessment-link { font-size: 16px; }
  }
  
  .edit-main-body-css-styles {
    & { padding: 10px; }
    button { 
      & { border: 2px solid #212A42; width: 100%; background-color: transparent; border-radius: 4px; display: flex; justify-content: space-between; align-items: center; }
      img { width: 24px; height: 24px; }
      p { margin: 10px 0; text-transform: uppercase; font-weight: 500; font-size: 18px; }
    }
  }

  .options-container {
    & { display: flex; justify-content: space-between; align-items: center; gap: 15px; padding: 10px; }
    button {
      & { border-radius: 4px; background-color: transparent; border: 2px solid #212A42; width: 109px; min-height: 110px; }
      h3 { font-size: 13px; margin-top: 5px; }
    }
  }
}

.aside {
  & { font-family: $mainfont; width: 90%; background: #ecf0f1; padding: 0; }

  .header { width: 100%; padding: 15px 2%; margin: 0; background: #FFF;box-shadow: rgba(0,0,0,0.2); text-transform: uppercase; }
  .content { margin: 20px; text-align: justify; }
  .actionBtn { display: flex; justify-content: flex-end; margin: 20px; }
}

.navbar {
  & { display: flex;flex-direction: column;width: 340px;background: #ced6e0; }
  a { & { display: block; width: 80%; margin: 0 auto; color: #FFF; text-decoration: none; font-size: 1.2em; padding: 5px 10px; border-bottom: 1px solid #3d8fb6; border-top: 1px solid #277499; font-family: $mainfont; }
      &:first-of-type { border-top: none; }
      &:last-of-type { border-bottom: none; }
      &:hover { color: #ecf0f1; }
  }
}

.App { display: flex; font-family: $mainfont, sans-serif; }

/* -- Menu Main -- */
/* -- ========= -- */

.sidebar-arrow {
  & { height: 25px; width: 25px; position: absolute; background: #212a42; color: white; border-top-right-radius: 15px; border-bottom-right-radius: 15px; transition: .1s; right: -22px; top: 15px; padding-left: 5px; }
  img { width: 17px; cursor: pointer; }
  .right-arrow-logo { cursor: pointer; display: flex; justify-content: center; }
  .left-arrow-logo { cursor: pointer; display: flex; justify-content: center;}
  svg { font-size: 2rem; height: 25px; width: 25px; }
}

.menu {

    & { position: fixed; width: 300px; height: 100vh; flex: 0 1 300px; background: #212A42; color: #fff; transition: .3s; }
    .logo-arrow-container {
      & { display: flex; padding: 0 15px; transition: .5s; position: relative; }
      .company-logo {
        & { text-align: center; margin: 10px auto;}
        img { width: 140px; transition: .5s; }
      }
      .company-logo-closed {
        img { width: 56px; transition: .5s; }
      }
    }
    nav { margin: 20px 12%; margin: 0; }

    nav { & { position: relative; }
      a { display: flex; width: 100%; margin: 0 auto; color: #ffffff; text-decoration: none; font-size: 1em; font-weight: 400;padding: 8px 5px; background: none; margin: 0 auto; }
      .MuiSvgIcon-root { margin-top: -2px; margin-right: 10px; }
      a:first-of-type {border-top: none;}
      a:last-of-type {border-bottom: none;}
      a:hover {color: #F2D466; }
      .welcome-user { text-align: center; font-size: 24px; font-weight: 600; font-family: $fontmontserrat; }
      .logout { cursor: pointer; }
      h4.assessments-header { margin: 0; font-family: $fontmontserrat; font-weight: 400; font-size: 18px; padding: 0 5%; letter-spacing: 1px; }
      .assessments-list { & { margin: 0 auto; height: calc(100vh - 400px); background-color: rgba(255,255,255,0.05); overflow-y: auto; margin: 10px 0; overflow-x: hidden; }
        &::-webkit-scrollbar { width: 4px; }
        &::-webkit-scrollbar-track { box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }
        &::-webkit-scrollbar-thumb { background-color: #FBF9F2; border-radius: 20px; outline: 1px solid slategrey; }
        ul { & { padding: 0; list-style-type: none; }
          .assessment-item { & { padding: 10px 7.5%; padding: 10px; cursor: pointer; font-family: $fontmontserrat; font-weight: 400; font-size: 17px; line-height: 22px; text-transform: capitalize; display: flex; align-items: center; }
            &:hover { color: #F2D466; 
              .assessment-num { background-color: #F2D466; }
            }
            .assessment-num { background-color: #FBF9F2; color: #000000; font-family: $fontmontserrat; font-weight: 600; font-size: 17px; display: flex; align-items: center; justify-content: center; width: 32px; height: 32px; text-align: center; border-radius: 50%; padding: 5px; margin-right: 10px; }
          }
          .assessment-dropdown-hide { display: none; }
          .assessment-dropdown-active { & { padding-left: 65px; }
            li { cursor: pointer; padding: 4px 0 12px 0; }
          }
          .assessment-dropdown-active li:hover{ color: #F2D466; }
          .active-assessment { & { background-color: #0F131D; color: #F2D466; font-weight: 600; font-size: 16px; }
            .assessment-num { background-color: #F2D466; }
          }
          .active-assessment.hide-active { color: #fff; background-color: rgba(255,255,255,0); font-weight: 400; }
        }
      }
      .bottom-links { 
        & { bottom: 0; margin-left: 12px;  margin-bottom: 5px; width: auto; height: 240px; position: fixed; left: 0; }
        //& { bottom: 0; margin: 0 10px 10px 20px; width: auto; position: fixed; left: 0; } // position: fixed; }
        // .lower-icon { margin-right: 10px; background: #fff; border-radius: 20px; display: flex; justify-content: center; align-items: center; height: 24px; width: 24px; }
        .links-container {
          & { height: 100%; position: relative; }
          a.logout { position: absolute; bottom: 0; text-wrap: nowrap; }
        }
        .MuiSvgIcon-root { margin: 0; }
      }
      span {
        &.fade-out { opacity: 1; transition: opacity 1s; text-wrap: nowrap; width: 20%; }
        &.fade-in { opacity: 0; transition: opacity 1s; }
      }
      .account { height: calc(100vh - 295px); background: none; }
    }


    .menulogo { height: 32px;margin: 6px 0 2px -20px; }

    /*.container {height: 100%;}
    nav h2 {color: #fff;font-size: 1.5em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 800;}
    nav h3 {color: #fff;font-size: 1.2em;letter-spacing: 0.1em;text-transform: uppercase;width: 90%;margin: 0 auto;padding: 15px 5%;font-weight: 600;}
    nav a {display: block;width: 90%;margin: 0 auto;color: #fff;text-decoration: none;font-size: 1.1em;font-weight: 300;padding: 15px 5px;background: none;border-bottom: 1px solid $menudividerlight;border-top: 1px solid $menudividerdark;}
    nav .profile {background: $themeaccentcolor1;padding: 20px;text-align: center;font-size: 1.4em;display: flex;align-items: center;}
    nav .profile .profilepic {flex: 0 0 60px;height: 47px;border-radius: 50%;background: $themecolor1;padding-top: 13px;}
    nav .profile .profiledetails {flex: 1 1 auto;font-size: 14px;text-align: left;line-height: 16px;margin-left: 10px;}
    nav .profile .profiledetails span {font-size: 12px;}
    nav .submenu {padding: 5px 5px 5px 20px;font-size: 0.9em;border-top: 1px solid $submenudividerlight;border-bottom: 1px solid $submenudividerdark;}
    nav .sub {background: $themeaccentcolor1;}
    nav a:first-of-type {border-top: none;}
    nav a:last-of-type {border-bottom: none;}
    nav a:hover {color: #ecf0f1;}
    nav a i {display: block;float: left;margin-top: -4px;margin-right: 20px;font-size: 28px;opacity: 0.3;}
    .logo { padding: 15px 10px; width: 90%; border: none; line-height: 0; }
    .logo img { width: 150px; }*/
}

.close-sidebar { width: 90px; }

.login-register {
  & { background: #212A42; display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 60px; height: 100vh; width: calc(100% + 300px); margin-left: -300px; }
  .login {
    & { margin-top: 0; display: flex; flex-direction: column; align-items: center; justify-content: center;}
    img.bleat-logo { max-width: 140px; width: 90%; }
    .form-heading { 
      h1 { font-family: 'Butler'; font-style: normal; font-weight: 700; font-size: 48px; line-height: 58px; text-align: center; color: #F2D466; margin-top: 75px; margin-bottom: 4px; }
      p { font-family: 'Montserrat'; font-style: normal; font-weight: 600; font-size: 24px; line-height: 29px; text-align: center; color: #FFFFFF; margin: 0; }
    }
    .form-wrapper { & { margin: 63px 0px 0px; }
      form { margin-bottom: 0px; }
      .helper-text { font-family: 'Montserrat'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 22px; color: #FFFFFF; position: absolute; margin-left: -120px; }
      .password-container {
        & { display: flex; align-items: center; background: #f5f5f5; border-radius: 4px; }
        svg { color: #bebebe; }
        .MuiOutlinedInput-notchedOutline { border: none; }
        .eye-icon { padding: 10px; display: flex; align-items: center; }
      }
      .MuiGrid-root {  max-width: 320px; }
      .MuiGrid-spacing-xs-4 { width: calc(100% + 16px); }
      .MuiGrid-spacing-xs-4 > .MuiGrid-item { padding: 8px; }
      .MuiFormLabel-root { font-size: 14px; } 
      .MuiInputBase-root { font-family: 'Montserrat'; font-weight: 600; }
      .MuiOutlinedInput-input { font-size: 14px; background: #fff; border-radius: 3px; }
    }
    .forgot-password {
      & { font-family: 'Montserrat'; font-style: italic; font-weight: 500;font-size: 16px; line-height: 20px; color: #FFFFFF; margin-left: -4px; }
      a { color: #F2D466; text-decoration: none; cursor: pointer; }
    }
    .sign-in {
      & { font-family: 'Montserrat'; font-style: italic; font-weight: 500;font-size: 16px; line-height: 20px; color: #FFFFFF; margin-left: -16px; }
      a { color: #F2D466; text-decoration: none; cursor: pointer; }
    }
    .remember-forgot-wrapper {
      & { margin: 10px 0px 30px; display: flex; flex-wrap: wrap; justify-content: space-between; }
      a { color: #007C89; text-decoration: none; font-size: 14px; font-weight: 400; }
    }
    .no-account-wrapper {
      & { margin-top: 20px; color: #292F36; font-size: 16px; font-weight: 400; }
      a { color: #007C89; text-decoration: none; }
    }
    .outlineBtn { margin: 2rem auto; margin-left: -8px; width: 100%; background: #F2D466; border-radius: 3px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; height: 54px; font-size: 18px; line-height: 22px; text-transform: uppercase; color: #212A42;}
    .visibility { cursor: pointer; }

  }

}

.question-delete-modal {
  .MuiBox-root {
    & { border-radius: 5px; }
    .add-goal-form {
      & { max-width: 400px; max-height: 232px; }
      .heading-and-close-icon-container {
        & { display: flex; }
        .close-icon { 
          & { width: 24px; cursor: pointer; }
          svg {
            & { width: 24px; height: 24px; } 
            path { fill:#000000; }
          }
         }
        p { font: normal normal 600 20px/24px $fontmontserrat; letter-spacing: 1px; color: #000000; margin-top: 0; }
      }
      .form-btns {
        & { display: flex; flex-direction: column; gap: 16px; }
        .delete-btn { background-color: #122845; color: white; text-transform: capitalize; text-align: left; font: normal normal medium 20px/24px $fontmontserrat; letter-spacing: 0px; color: #FFFFFF; }
        .cancel-btn { color: #122845; border: 2px solid #122845; text-transform: capitalize; text-align: left; font: normal normal medium 20px/24px $fontmontserrat; letter-spacing: 0px; }
      }
    }
  }
}

.MuiList-root {
  li {
    & { display: block; width: 100%; }
    p.parent-container-option { display: flex; justify-content: space-between; }
  }
}

.category-delete-modal {
  .MuiBox-root {
    & { border-radius: 5px; }
    .add-goal-form {
      & { max-width: 400px; max-height: 232px; }
      .heading-and-close-icon-container {
        & { display: flex; }
        .close-icon { 
          & { width: 24px; cursor: pointer; }
          svg {
            & { width: 24px; height: 24px; } 
            path { fill:#000000; }
          }
         }
        p { font: normal normal 600 20px/24px $fontmontserrat; letter-spacing: 1px; color: #000000; margin-top: 0; }
      }
      .form-btns {
        & { display: flex; flex-direction: column; gap: 16px; }
        .delete-btn { background-color: #122845; color: white; text-transform: capitalize; text-align: left; font: normal normal medium 20px/24px $fontmontserrat; letter-spacing: 0px; color: #FFFFFF; }
        .cancel-btn { color: #122845; border: 2px solid #122845; text-transform: capitalize; text-align: left; font: normal normal medium 20px/24px $fontmontserrat; letter-spacing: 0px; }
      }
    }
  }
}


.main {
  & { height: 96vh; flex: 1 1 auto; background-color: #FBF9F3; color: $titlecolor1; margin-left: 300px; transition: .5s; }
  h1 { color: $titlecolor1; }
  h1 a { color: $titlecolor1; text-decoration-thickness: 2px; text-decoration-color: #c8c8c8; }

  .container { 
    
    & { width: 92%; height: 96vh; padding: 0 4% 4vh; overflow-y: auto; }

    header {       
      & { display: flex; align-items: center; justify-content: space-between; }
      .header-cta { display: block; cursor: pointer; background: #007C89; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
    }

  }
  .clients, .container {
    .card-container { 
      &{width: 100%; text-align: center; display: grid; grid-template-columns: 1fr 1fr 1fr; grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); justify-content: center; grid-gap: 20px; margin: 0 auto; }
      // .MuiTable-root { width: 80%; padding: 20px 30px;}
      .card-table { }
      .card {
        & {display: flex; flex-direction: column; align-items: center; width: calc(100% - 60px); padding: 20px 30px; min-width: 300px; }
        .card-title {font-size: 22px; font-weight: 700; margin-bottom: 25px; }
        .logo-img { width: 100%; height: 100px; margin-bottom: 25px; border-radius: 5px; display: flex; justify-content: center; align-items: center; }
        .logo-img img { border-radius: 5px; height: auto; max-height: 120px; width: auto; max-width: 300px; }
        .logo { width: 100%; height: 100px; margin-bottom: 25px; padding: 5px 0; border: 2px dashed #92929292; border-radius: 5px; display: flex; flex-direction: column; justify-content: center; align-items: center; }
        .logo .MuiSvgIcon-root { font-size: 70px !important; opacity: 0.7; }
        .card-stats {font-size: 20px; display: flex; width: 80%; justify-content: space-evenly; font-weight: 600; margin-bottom: 25px;}
        .icons {display: flex; justify-content: space-evenly; width: 80%;}
      }
    }
  }
  
  .MuiTable-root { 
    & { color: $titlecolor1; font-family: $mainfont, sans-serif; }
    a { color: $titlecolor1; }
    h3 { padding: 0; margin: 0; }
    .no-underline { text-decoration: none; }
    .assessment-link { cursor: pointer; }
    .assessment-link:hover { text-decoration: underline; }
  }
  .MuiTableCell-head { color: $titlecolor1; font-weight: 700; font-family: $fontmontserrat, sans-serif; }
  .MuiTableCell-body { & { color: #0f131d; font-family: $fontmontserrat, sans-serif; }
    .variables-container { 
      & { display: flex; align-items: center; justify-content: center; }
      .text-icon-container {
        & { width: 24px; height: 24px; }
        .text { margin: 0; }
        img { width: 24px; }
      }
    }
    .order-and-drag-container { display: flex; align-items: center; }
    .question-text-icon-container { display: flex; gap: 10px;}
    .type-txt{ 
      &{ display: flex; align-items: center; gap: 10px; }
        p{ text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #0F131D; }
    }
    .edit-and-trash-icon {
      &{ display: flex; align-items: center; gap: 8px; font-size: 1.2rem; }
    }
  }

  .table-tool { cursor: pointer; }
  .table-tool-small { cursor: pointer; margin: 6px 0 -6px 0; }

}
.edit-chart-modal { width: 300px !important; }
.MuiDrawer-paper::-webkit-scrollbar { display: none; }
.MuiDrawer-paper { -ms-overflow-style: none; scrollbar-width: none; }
.container .create-assessment-drawer{ & {width: 100vw;}
  header{ & {position: relative; background-color: transparent; width: 100%; padding: 0;}
    h1{margin: 0; padding: 20px 0;}
  }
  .add-question-drawer{
    .question-mandatory-toggle{width: 95%; margin: auto; margin-top: 32px;}
    h2{margin: 0;}
    .center-flex{ width: 100%; height: 100%; display: flex; justify-content: center; align-items: center; flex-direction: column; flex: 1; max-width: 600px; margin: auto; overflow: hidden; padding-bottom: 150px; -ms-overflow-style: none; scrollbar-width: none;}
    .start-flex{ width: 100%; height: 100%; padding-top: 75px; max-width: 600px; margin: auto; overflow: hidden; -ms-overflow-style: none; scrollbar-width: none;}
    .start-flex::-webkit-scrollbar, .center-flex::-webkit-scrollbar { display: none; }
    .react-select{width: 95%;}
    .type-assessment{ &{display: flex; justify-content: flex-start; align-items: flex-start; gap: 20px; margin: 20px 0;}
      input[type="radio"] {
        -ms-transform: scale(1.5); /* IE 9 */
        -webkit-transform: scale(1.5); /* Chrome, Safari, Opera */
        transform: scale(1.5);
        accent-color: #212121;
        margin-top: 10px;
      }
      .assessment-info {
        h3{margin: 0;}
      }
    }
    .columns {
      & { display: grid; grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); grid-gap: 20px; overflow-y: auto; }
    }
    .columns-list-content {
     & { padding: 0px 10px; }
    .arrow { cursor: pointer; }
    .list-content { background-color: rgba(241, 241, 241, 1); border-radius: 8px; display: flex; justify-content: space-between; align-items: center; padding: 0px 10px; margin-bottom: 10px; width: auto; }
    .disabled-list-content { background-color: rgb(183, 181, 181); }
    }
    .selected-coulmn-list {
      & { padding: 0px 10px;border-left: 1px solid #F4F2EC; }
      .columns-list-child {  background-color: rgb(241, 241, 241); border-radius: 8px;   align-items: center;   padding: 10px 20px;   margin-bottom: 10px; width: auto; display: flex;
        justify-content: space-between; align-items: center;  }
       
     }
     .order-no-and-drag-section-name-icon {  display: flex; gap: 20px; align-items: center; }
     .drag-section { display: flex; align-items: center; }
     .icons { & {display: flex;  justify-content: end;  margin-right: 24px; gap: 20px;}
        svg{cursor: pointer;}
      }
     .section-name { font-family: $fontmontserrat; letter-spacing: 0px; color: #212121; font-size: 16px; font-weight: 600; margin-bottom: 7px; }
     p{margin: 5px 0;}
    .additional-questions .MuiSwitch-thumb{ position: absolute; top: 6px; left: 5px;}
    .MuiSwitch-colorSecondary.Mui-checked { color: #122845}
    .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track { background-color: #122845;}
    .assessment-collection-details{ &{display: flex; justify-content: center; align-items: center; gap: 50px;}
      .detail{ &{display: flex; justify-content: center; align-items: center; gap: 15px}
        input[type="radio"] {
          -ms-transform: scale(1.25); /* IE 9 */
          -webkit-transform: scale(1.25); /* Chrome, Safari, Opera */
          transform: scale(1.25);
          accent-color: #212121;
          margin: 0;
        }
        h4{margin: 0;}
      }
    }
  }
}
.drawer {

  & { width: 90vw; }
  header { width: calc(100% - 60px); padding-left: 30px; }
  .react-select__menu { & { background-color: #ffffff; z-index: 2; }
  }
  .MuiTableCell-head { text-align: left; font: normal normal normal 14px/18px $fontmontserrat; letter-spacing: 0px; color: #5B5B5B; opacity: 1; }
  .MuiTableCell-body { color: $titlecolor1; font-family: $mainfont, sans-serif; width: 10%; }
  .table-tool { fill: #000000; cursor: pointer; }
  .MuiTabs-flexContainer { background: #fff; }

  section { background: #fff; padding: 30px; padding-top: 0px; min-height: calc(100vh - 130px); width: calc(100% - 60px); overflow-y: auto; }
  .double-column-form { 
    & { display: flex; align-items: center; gap: 20px; margin-bottom: 30px; }
    label { text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; }
    .MuiInputBase-fullWidth { width: 98%; }
    #file-type { top: -5px; left: 15px; }
    .cancel-button-add-category-subdrawer{
      &{ border: 2px solid #122845; border-radius: 4px; background: transparent; padding: 3px 18px; font-weight: 600; }
    }
    .submit-btn{ background-color: #122845; color: white; }
  }
  .dutch.double-column-form {
    .form-control-add-question { width: 38%; }
  }

  .edit-category-btn-container { 
    .cancel-btn { font-family: "Montserrat", sans-serif; background-color: #eb4d4b; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
  }

  .button-container {
    &{ display: flex; justify-content: flex-end; gap: 40px; align-items: center; margin-top: 20px; }
    .cancel-btn { text-align: left; font: normal normal bold 16px/21px $fontLato; letter-spacing: 0px; color: #122845; width: max-content; padding: 6px 85px; border: 2px solid #122845; }
    .add-question-btn { background: #122845; color: #fff; border-radius: 4px; padding: 7px 32px; font: normal normal bold 16px/21px "Lato", sans-serif; }
  }

  .margin-top { margin-top: 15px; }
  .aligned-right { justify-content: flex-end; margin-right: 1%; }

  .tab-title { text-transform: capitalize; font-family: $fontmontserrat; font-weight: 400; color: #5B5B5B; font-size: 16px; }
  .tab-title.Mui-selected { font-weight: 600; color: #1F2A44; }


  // User Details Drawer
  .user-details-header { & { display: flex; flex-direction: row; justify-content: space-between; align-items: center; }
    h1 { font-family: $fontbutler; font-size: 28px; margin: 32px auto 16px 0; letter-spacing: 1px; text-transform: capitalize; color: #1F2A44; }
    .close-user-details-drawer { cursor: pointer; position: relative; right: 60px; }
  }
  .referral-link-container { & { display: flex; justify-content: flex-start; align-items: center; }
    .referral-link-text { color: #1F2A44; font-weight: 600; font-size: 16px; font-family: $fontmontserrat; padding-right: 10px; }
    .referral-link .MuiInputBase-root { border-radius: 30px; overflow: hidden; margin-right: 10px; }
    .referral-link input { padding: 10px 15px; background-color: #F4F4F4; color: #919191; font-family: $fontmontserrat; width: 24ch; }
    .copy-referral-btn { background-color: #212A42; color: #FFFFFF; font-family: $fontmontserrat; font-size: 14px; font-weight: 600; border-radius: 30px; padding: 7px 15px; }
    .copy-referral-btn.copied { color: #212A42; background-color: #F2D466; }
    .copy-referral-btn.copied:hover { color: #212A42; background-color: #F2D466; }
    .copy-referral-btn:hover { background-color: #212A42; color: #FFFFFF; }
  }
  .user-personal-container { display: grid; grid-template-columns: 1fr 1fr; }
  .info-section { & { margin: 15px 0; font-family: $fontmontserrat; }
    .info-title { font-weight: 700; color: #343333; font-size: 16px; margin: 5px 0; }
    .info-value { font-size: 18px; font-weight: 400; margin: 5px 0; }
    .add-question-btns { font-family: $fontLato; }
  }
  .user-score-container { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; }
  .user-score-container a { text-decoration: none; }
  .score-section { & { margin: 30px; font-family: $fontmontserrat; }
    .score-title { font-weight: 700; color: #343333; font-size: 16px; margin: 10px; }
    .score-value { font-size: 21px; font-weight: 400; margin: 10px; } 
  }

  .user-score-details { 
    a { text-decoration: none; }
    .score-section { padding: 15px 0; }
    .view-results-btn { background-color: #212A42; color: #ffffff; margin: 15px 30px; padding: 15px 25px; font-size: 14px; font-family: $fontmontserrat; }
    .additional-questions-answers { & {}
      h3 { font-size: 21px; }
      .qa-container {
        p { font-size: 18px; max-width: 1100px; }
        .question { margin: 18px 0 8px; padding: 5px 0 0; font-weight: 700; }
        .answer { margin: 8px 0 18px; }
        .left-text { display: inline-block; width: 4ch; text-align: center; font-weight: 700; }
      }
    }
  }
  .display-block {display: block; margin-bottom: 15px;}
  .user-referrals { & {  }
    table { & {  }
      // thead tr th { text-align: center; }
      th { font-family: $fontmontserrat; font-weight: 700; color: #343333; font-size: 16px; margin: 10px; }
      // th:nth-child(4) { text-align: center; }
      td { font-family: $fontmontserrat; font-weight: 400; font-size: 16px; color: #1F2A44; }
      .referral-info-link { text-transform: capitalize; }
      th a { color: #1F2A44; font-weight: 400; }
      .copy-referral-btn { background-color: #212A42; color: #FFFFFF; font-family: $fontmontserrat; font-size: 14px; font-weight: 600; border-radius: 4px; padding: 7px 15px; text-transform: capitalize; width: max-content; }
      .copy-referral-btn.copied { color: #212A42; background-color: #F2D466; }
      .copy-referral-btn.copied:hover { color: #212A42; background-color: #F2D466; }
      .view-results-link { text-decoration: none; }
      .view-results-link button { background-color: #212A42; color: #FFFFFF; font-family: $fontmontserrat; font-size: 14px; font-weight: 600; border-radius: 4px; padding: 7px 15px; text-transform: capitalize; width: max-content; }
      .assessment-status { font-weight: 600; }
      .assessment-complete { color: #0AC74A; }
      .assessment-pending { color: #EB7A24; }
      .assessment-invited { color: #afafaf; }
    }
    table .MuiTableCell-body { width: auto; }
  }
  .react-select {left: 5px;}
  .react-select__value-container {height: 55px;}
  
  .complete-create-assessment {
    .MuiSvgIcon-root {height: 150px; font-size: 150px; color: green;}
    .MuiButton-containedPrimary{ background-color: #212A42;}
    .MuiButton-root {font-weight: 600; font-family: $mainfont;}
  }
  .complete-create-assessment.MuiButton-containedPrimary{ background-color: #212A42;}
  .complete-create-assessment.MuiButton-root {font-weight: 600; font-family: $mainfont;}

  .google-sheets { & {  }
    .form-headers { & { display: flex; box-shadow: 0px 2px 10px #00000014; border-radius: 8px; font-family: $fontmontserrat; }
      .form-header { & { display: flex; flex-direction: column; text-align: center; margin-left: 20px; cursor: pointer; margin-top: 10px; } 
        label { color: #9B9B9B; font-weight: 700; font-size: 14px; }
        small { color: #9B9B9B; font-size: 10px; font-weight: 600; }
      }

      .form-header-active { & { display: flex; flex-direction: column; text-align: center; margin-left: 20px; margin-top: 10px; border-bottom: 4px solid #F2D466; cursor: pointer; } 
        label { color: #000000; font-weight: 700; font-size: 14px; }
        small { color: #000000; font-size: 10px; font-weight: 600; margin-bottom: 5px; }
      }
    }

    .row-section { & { display: flex; margin-top: 20px; font-family: $fontmontserrat; }
      .MuiFormGroup-root { & { display: flex; flex-direction: row; }
      .MuiFormControlLabel-label { color: #000000; font-size: 16px; font-weight: 700;}
    }
  }

    .columns { & { margin-top: 20px; display: flex; flex-direction: row; flex-wrap: wrap; justify-content: space-between; font-family: $fontmontserrat; }
    .column { & { display: flex; flex-direction: column; margin: 10px 2px; }
      label { color: #212121; font-weight: 700; font-size: 16px; margin-bottom: 2px; }
      .MuiInputBase-input { border: 1px solid #BEBEBE; height: 26px; min-width: 480px; border-radius: 4px; font-family: $fontmontserrat; font-weight: 500; font-size: 16px; padding-inline-start: 5px; }
      .MuiInput-underline::before { border-bottom: none; }
      .MuiInput-underline::after { border-bottom: none; }
    }
  }

  .field-and-columns { & { margin-top: 20px; display: flex; flex-direction: row; justify-content: space-between; overflow-y: scroll; scroll-behavior: smooth; }
    .column-data { & { display: flex; flex-direction: column; }
    .text-field { & { justify-content: start; height: min-content; }
    .MuiTouchRipple-root { border-bottom: 3px solid #4D9950; }
      }
      .add-text-field { justify-content: start; height: 45px; width: 160px; margin: 5px; border-radius: 4px; border: 1px solid #BEBEBE; background-color: #FFFFFF; display: flex; align-items: center; font-size: 24px; cursor: pointer; }
      .text-field-input input { font-size: 18px; }
      .MuiButton-root { height: 45px; width: 160px; margin: 5px; }
      .MuiButton-label { font-weight: 700; }
      .MuiInputBase-input { border: 1px solid #BEBEBE; height: 26px; width: 156px; border-radius: 4px; font-family: $fontmontserrat; font-weight: 500; font-size: 16px; padding-inline-start: 5px; margin-top: 5px; }
      .MuiInput-underline::before { border-bottom: none; }
      .MuiInput-underline::after { border-bottom: none; }
      .MuiInput-input { font-weight: 600; font-size: 24px; color: #000000; }
      .MuiFormControl-root { margin: 5px; }
  }
    .add-columns { & { padding: 5px; height: 105px; }
    .MuiButton-label { font-weight: 600; font-size: 24px; justify-content: start; align-items: center; }
  }  

 }
}

}

.add-category-drawer{
  .drawer{ width: 80vw; }
}

.w60 {width: 60%; margin: auto;}



.drawer.user-details { & { width: 55vw; width: 80vw; }
  section { padding-top: 0; }
}

.link-copied-snackbar { .MuiSnackbarContent-root { min-width: 30ch; justify-content: center;  }}

.create-page-modal-container.MuiBox-root { width: 550px; }
.create-assessment-page-modal-container.MuiBox-root { width: 50vw; height: 55vh; overflow: hidden; border-radius: 5px; }
.delete-assessment-page-modal-container.MuiBox-root { width: 500px; height: 100px; overflow: hidden; border-radius: 5px; }
.modal {
  #rbgcp-wrapper{
    & { width: 307px; bottom: 0; position: absolute; z-index: 1; right: -56%; height: 100%; background: white; padding: 10px; box-sizing: border-box; border-radius: 10px; }
  }
  & { font-family: $mainfont, sans-serif; }

  .tox-tinymce-inline { z-index: 9999; }

  .footer {
    & { display: flex; justify-content: space-between; }
    //.cancel-btn { background: #fff; }
    //.cancel-btn:hover { background: #efefef; }
    .cancel-btn { font-family: $fontmontserrat; background-color: $buttoncolor1; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
    .cancel-btn:hover { background-color: $buttonaccentcolor1; color: #fff; }
    .confirm-btn { font-family: $fontmontserrat; background-color: #007C89; color: #fff; padding: 6px 14px; border-radius: 3px; text-transform: uppercase; font-weight: 800; font-size: 14px; }
    .confirm-btn:hover { background-color: #00a5b8; color: #fff; }
  }

  // for drawer nested inside modal 
  .drawer { & { width: auto;}
    header { & { padding: 0; }
      h1 { transition: all 0.1s; }
    }
    section { padding: 0; min-height: auto; width: 100%; }
  }

  .assessment-leader-info-table {&{max-height:50vh;overflow-y: scroll;}

    table tr {
      th { font-family: $fontmontserrat; font-weight: 700; color:#122845;border-bottom:0;}
      td { font-family: $fontmontserrat; font-weight: 500; color:#122845;border-bottom:0; }
    }
    table { border-radius: 10px; }
  }
  .assessment-leader-info-table::-webkit-scrollbar {
    display: none; 
   }
 .assessment-leader-info-table {
    scrollbar-width: none; 
 }
  .css-1q1u3t4-MuiTableRow-root{
    border: 1px solid #E6E6E6;
  }
}


.add-new-style-modal-parent {
  & { width: 60%; max-width: 674px; margin: auto; }
  .MuiBox-root { padding: 10px; border-radius: 11px; }
  .modal.create-new.style-modal { 
    & { padding: 20px; }
    .heading-and-close-btn-container {
      & {  display: flex; justify-content: space-between; }
      h1 { font-weight: bold; font-size: 30px; margin: 0; }
      button { 
        svg { color: #000; }
      }
    }  
    .set-multiple-styles-container {
      & { display: flex; justify-content: space-between; margin-top: 25px;  }
      .heading { margin: 0; }
    }
    .style-property-input-container { & { margin: 0; position: relative; width: 100%; }
      h3 { margin: 10px 0; }
    }
    .value-input-container { 
      & { margin-top: 20px; }
      .border-unit.styling-select { 
          .MuiSelect-select {
            & { padding: 10px; border-radius: 0; border: 1px solid #969696; } 
          }
        }
      .border-styles.tool {
        & { position: relative; }
        .chrome-picker { position: absolute; right: -51%; top: -43%; }
      }
      .bg-color-picker { width: auto !important; }
      .padding-input-container { 
        & { display: flex; align-items: center; gap: 15px; justify-content: space-between; }
        .padding-input {
          .input-heading { padding-bottom: 5px; }
          .input-container {
            & { display: flex; align-items: center; }
            input { padding: 0; text-align: center; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; }
          .styling-select {
            & { width: 50%; padding: 0; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; display: flex; justify-content: center; align-items: center; }
            .MuiSelect-select { padding-left: 5px; padding-right: 10px; }
          }
          }
        }
      }    
      .margin-input-container { 
        & { display: flex; align-items: center; gap: 15px; justify-content: space-between; }
        .margin-input {
          .input-heading { padding-bottom: 5px; }
          .input-container {
            & { display: flex; align-items: center; }
            input { padding: 0; text-align: center; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; }
          .styling-select {
            & { width: 50%; padding: 0; height: 41px; max-height: 41px; background-color: #FFFFFF; border: 1px solid #707070; display: flex; justify-content: center; align-items: center; }
            .MuiSelect-select { padding-left: 5px; padding-right: 10px; }
          }
          }
        }
      } 
    }
    input { padding: 10px; border: 1px solid #707070; width: 94%; font-weight: 500; }
    .align-items.tool{ 
      .styling-select { width: 98%; }
    }
    .bgcolor-input { width: 80%; }
    .color-box { width: 45px; height: 45px; }
    .backgroundimg { 
          .styling-select { width: 98%; }
        }
    input::placeholder { color: #979797; font-size: 14px; }
    .new-style-suggestionbox-container {
      & { position: absolute; width: 100%; background: white; box-shadow: 0px 2px 10px #00000014; z-index: 1; max-height: 45vh; height: auto; overflow-y: scroll; }
      &::-webkit-scrollbar { width: 5px; }
      &::-webkit-scrollbar-thumb { background: #000; }
      p { 
        & { margin: 0; padding: 10px 20px; cursor: pointer; }
        &:hover { background: #f8f1f1; }
      }
    }

    .block-id-input-container {
      & { position: relative; }
      .content-type { position: absolute; bottom: 8px; right: 18px; }
      .block-id-suggestionbox-container {
        & { position: absolute; top: 100%; left: 0; width: 100%; background-color: #fff; box-shadow: 0px 2px 10px #00000014; z-index: 1; box-sizing: border-box; max-height: 45vh; height: auto; overflow-y: scroll; }
        &::-webkit-scrollbar-thumb { background: #000; }
        &::-webkit-scrollbar { width: 5px; }
        p {
          & { display: flex; align-items: center; justify-content: space-between; width: 100%; cursor: pointer; padding: 5px 10px; box-sizing: border-box; }
          &:hover { background: #f8f1f1; }
        }
      }
    }

    .all-builders-confirmation { 
      & { display: flex; }
      span { 
        & { padding-left: 0; }
        svg { font-size: 25px; }
      }
    }
    .create-btn-container { 
      button { background-color: #122845; border-radius: 13px; color: #fff; font-size: 18px; width: 100%; font-weight: bold; }
    }
  }
  
}

.modal.sync-modal{
  & { padding: 12px; }
  .container {
    & { display: flex; gap: 24px; flex-direction: row; width: 500px; justify-content: center; align-items: center; }
    .flex-container {
      & { display: flex; flex-direction: column; justify-content: space-between; align-items: center; gap: 12px; }
    }
    .outline-container {
      & { border: 1px solid #585858; padding: 12px; width: 180px; height: 70px; text-align: center; display: flex; justify-content: center; align-items: center; }
      .text { font-size: 16px; font-weight: 600; font-family: $fontmontserrat; color: #212A42; }
      :hover { border-color: #212A42; }
    }
    .fillBtn { border-radius: 4px; width: 80px; padding: 12px; background-color: #212A42; color: #fff; font-family: $fontmontserrat; font-weight: 600; font-size: 16px; }
    .outline-container::hover{
      border-color: #212A42;
    }
  }
}

.modal.merge-parish{
  & { padding: 12px; }
  .parish-subtext { font-size: 18px; margin: 24px 0 10px; }
  .cta-close-parish { height: 30px; }
  .merge-parish-body {
    .parish-warning { color: #ff0000; font-size: 14px; margin: 5px 0 8px; }
    .btn-container { 
       & { display: flex; justify-content: space-between; align-items: center; gap: 24px; }
       .outline-btn { cursor: pointer; flex: 1; border-radius: 4px; padding: 10px; border: 2px solid #212A42; font-size: 16px; font-weight: 600; font-family: $fontmontserrat; color: #212A42; text-transform: uppercase; }
       .fill-btn { cursor: pointer; flex: 1; border-radius: 4px; padding: 12px; background-color: #212A42; color: #fff; font-family: $fontmontserrat; font-weight: 600; font-size: 16px; text-transform: uppercase; }
      }
  }
}

.add-new-property-modal-parent {
  & { width: 60%; max-width: 674px; margin: auto; }
  .MuiBox-root { padding: 40px; border-radius: 11px; }
  .modal.create-new.property-modal { 
    & { padding: 20px; }
    .heading-and-close-btn-container {
      & {  display: flex; justify-content: space-between; }
      h1 { font-weight: bold; font-size: 30px; margin: 0; }
      button { 
        svg { color: #000; }
      }
    }  
    .style-property-input-container { & { margin: 0; margin-top: 25px; }
      h3 { margin: 10px 0; }
    }
    .new-style-suggestionbox-container {
      & { position: absolute; width: 89%; background: white; box-shadow: 0px 2px 10px #00000014; z-index: 1; max-height: 45vh; height: auto; overflow-y: scroll; }
      &::-webkit-scrollbar { width: 5px; }
      &::-webkit-scrollbar-thumb { background: #000; }
      p { 
        & { margin: 0; padding: 10px 20px; cursor: pointer; }
        &:hover { background: #f8f1f1; }
      }
    }
    .block-id-input-container {
      & { position: relative; }
      .block-id-suggestionbox-container {
        & { position: absolute; top: 100%; left: 0; width: 100%; background-color: #fff; box-shadow: 0px 2px 10px #00000014; z-index: 1; box-sizing: border-box; max-height: 45vh; height: auto; overflow-y: scroll; }
        &::-webkit-scrollbar-thumb { background: #000; }
        &::-webkit-scrollbar { width: 5px; }
        p {
          & { display: flex; align-items: center; justify-content: space-between; width: 100%; cursor: pointer; padding: 5px 10px; box-sizing: border-box; }
          &:hover { background: #f8f1f1; }
        }
      }
    }
    .value-input-container { margin-top: 20px; }
    input { width: 96%; padding: 10px; border: 1px solid #707070; color: #979797; font-size: 14px; }
    .all-builders-confirmation { 
      & { display: flex; }
      span { 
        & { padding-left: 0; }
        svg { font-size: 25px; }
      }
    }
    .create-btn-container { 
      button { background-color: #122845; border-radius: 13px; color: #fff; font-size: 23px; width: 100%; font-weight: bold; font-family: $fontLato; }
    }
  }
  .modal.create-new-block-modal { 
    & { padding: 20px; }
    h3 { margin-top: 14px; margin-bottom: 5px; }
    .content-type-list-input { width: 90%; margin-bottom: 10px; }
    .heading-and-close-btn-container {
      & {  display: flex; justify-content: space-between; }
      h1 { font-weight: bold; font-size: 30px; margin: 0; }
      button { 
        svg { color: #000; }
      }
    }  
    .style-property-input-container { & { margin: 0; margin-top: 25px; }
      h3 { margin: 10px 0; }
    }
    .new-style-suggestionbox-container {
      & { position: absolute; width: 89%; background: white; box-shadow: 0px 2px 10px #00000014; z-index: 1; max-height: 45vh; height: auto; overflow-y: scroll; }
      &::-webkit-scrollbar { width: 5px; }
      &::-webkit-scrollbar-thumb { background: #000; }
      p { 
        & { margin: 0; padding: 10px 20px; cursor: pointer; }
        &:hover { background: #f8f1f1; }
      }
    }
    .block-position-input-container {
      & { position: relative; }
      .heading-and-select-type-container {
        & { display: flex; align-items: center; gap: 10px; }
        .choose-content-type-container-input {
          & { width: 25%; border: 1px solid; border-radius: 4px; margin: 0; padding: 0; }
          .select-content-type-option {
            & { margin: 0; padding: 0 5px; }
          }
        }
      }
      .before-after-option-container { display: flex; gap: 70px; align-items: center; }
      .block-position-suggestionbox-container {
        & { position: absolute; top: 100%; left: 0; width: 100%; background-color: #fff; box-shadow: 0px 2px 10px #00000014; z-index: 1; box-sizing: border-box; max-height: 45vh; height: auto; overflow-y: scroll; }
        &::-webkit-scrollbar-thumb { background: #000; }
        &::-webkit-scrollbar { width: 5px; }
        p {
          & { display: flex; align-items: center; justify-content: space-between; width: 100%; cursor: pointer; padding: 5px 10px; box-sizing: border-box; }
          &:hover { background: #f8f1f1; }
        }
      }
    }
    .choose-page-type-input-container {
      .choose-page-input { width: 90%; border-radius: 5px; border: 1px solid; padding: 0 10px; }
    }
    .choose-page-area-input-container {
      .choose-page-area-input { width: 90%; border-radius: 5px; border: 1px solid; padding: 0 10px; }
    }
    .choose-parent-container {
      p.parent-container-option { display: flex; justify-content: space-between; margin: 0; }
      .choose-parent-container-input { width: 90%; border-radius: 5px; border: 1px solid; padding: 0 10px; }
    }
    .value-input-container { margin-top: 20px; }
    input { width: 96%; padding: 10px; border: 1px solid #707070; color: #979797; font-size: 14px; }
    .content-type-container { & { display: flex; overflow-y: scroll; gap: 10px; height: 177px; flex-wrap: wrap; }
      .content-type-and-content-type-image-container {
        & { cursor: pointer; padding: 10px; border: 2px solid #9B9B9B; border-radius: 8px; width: 126px; height: 134px; display: flex; flex-direction: column; align-items: center; }
        &:hover { background-color: #EBEAEA; }
        p { color: #9B9B9B; font-weight: bold; text-transform: capitalize; font-size: 12px; margin: 0; }
      }
      
      .content-type-and-content-type-image-container.selected {
        & { background-color: #EBEAEA; }
      }
    }
    .all-builders-confirmation { 
      & { display: flex; }
      span { 
        & { padding-left: 0; }
        svg { font-size: 25px; }
      }
    }
    .create-btn-container { 
      button { background-color: #122845; border-radius: 13px; color: #fff; font-size: 23px; width: 100%; font-weight: bold; font-family: $fontLato; }
    }
  }
}

.MuiBox-root {
  & { border-radius: 11px; }
  .wrap-container-modal {
    & { width: 600px; }
    .heading-and-close-btn-container {
      & {  display: flex; justify-content: space-between; }
      h1 { font-weight: bold; font-size: 30px; margin: 0; color: #57606f; }
      button { 
        svg { color: #000; }
      }
    }
    .form {
      & { display:flex;gap:10px; flex-direction: column; }
      .form-detail { & { margin-top: 15px; }
        .input-title { text-align: start; font-size: 16px;font-weight: 700; line-height: 24px; color: #343333; margin-bottom: 2px;margin-top:4px; }
        .input-desc{ margin-bottom: 6px; color: #03030380; font-size: 16px;font-weight: 300; line-height: 19.2px; margin-top: 0;}
        .select-container{position: relative; margin-top: 10px;}
        .form-details-text {width:361px;height: 45px;; font-weight: 300; font-size: 18px; line-height: 21.6px;}
        .form-details-text .MuiOutlinedInput-input {  font-weight: 400; font-size: 18px; color: #343333; text-align: left; line-height: 24px; }
        .form-details-text .Mui-focused fieldset{ border: 2px solid #EE2B27; }
        .Mui-focused .MuiOutlinedInput-notchedOutline { border: 1px solid #E6E6E6; }
        .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {border-radius: 3px; border: 1px solid #E6E6E6;}
        .MuiMenuItem-root{text-align: left;}
        
        input::placeholder { font-weight: 400; color:rgba(52, 51, 51, 0.50); opacity: 1 !important; } 
      }
      .form-detail {
        & { position: relative; }
        .select-placeholder { font-weight: 400; color:rgba(52, 51, 51, 0.50); opacity: 1 !important; font-size: 18px; line-height: 22px; }
      }
      .weekend-attendance{
        .slider-container{ &{display: flex; justify-content: center; align-items: center; margin-top: 35px; gap: 10px; }
          .side-label-left{color: #34333380; font-weight: 400; font-size: 14px; line-height: 17px; margin: 2px;}
          .side-label-right{color: #34333380; font-weight: 400; font-size: 14px; line-height: 17px; margin: 2px;}
          .MuiSlider-root{ &{color: #84161D; width: 100%; flex-shrink: 1;} 
            .MuiSlider-rail{ background-color: #0000001A;}
            .MuiSlider-thumb { & {background-repeat: no-repeat;border-radius: 0; width: 15px; height: 23px; box-shadow: none; background-color: #ffffff;}
              &::before{box-shadow: none;}
              .MuiSlider-valueLabel{background-color: transparent; color: #84161D; font-size: 22px; line-height: 27px; font-weight: 700; top: 0;}
            }
          }
        }
      }
      
      // .attendance{ padding-top: 5px; max-width: 216px;}
      // .attendance .MuiOutlinedInput-input::placeholder { font-family: $helvetica; font-weight: 300; font-size: 18px; color: rgba(52, 51, 51, 0.5); }
      // .zip{ padding-top: 10px; margin-top: 15px;}
      // .zip .MuiOutlinedInput-input::placeholder { font-family: $helvetica; font-weight: 300; font-size: 18px; color: rgba(52, 51, 51, 0.5); }
    } 
    .input-container { 
      input { width: 96%; padding: 10px; border: 1px solid #707070; color: #979797; font-size: 14px; }  
    }
    .all-builders-confirmation {
      & { display: flex; align-items: center; }
    }
    .create-btn-container { 
      button { background-color: #122845; border-radius: 13px; color: #fff; font-size: 23px; width: 100%; font-weight: bold; font-family: "Lato", sans-serif; }
    }
  }
}

.delete-style-modal-parent {
  & { display: flex; flex-direction: column; gap: 40px; }
  .question-close-icon-container {
    & { display: flex; align-items: center; justify-content: space-between; }
    h2 { font-size: 1.4rem; font-family: $fontLato; }
    .close-icon { 
      & { cursor: pointer; }
      svg { font-size: 2rem; }
     }
  }
  .delete-cancel-btn-container { 
    & { display: flex; align-items: center; gap: 20px; }
    h3 { font-size: 1.5rem; margin-top: 0; }
    button { cursor: pointer; padding: 10px; background-color: #122845; border-radius: 13px; color: #fff; font-size: 18px; width: 100%; font-weight: bold; text-transform: uppercase; }
  }
}

.navigator-modal-parent {
  .heading-close-btn-container { display: flex; align-items: center; justify-content: space-between; }
  .navigator-list {
    & { max-height: 60vh; overflow-y: scroll; background: #fff }
    .switch-view-container { display: flex; align-items: center; justify-content: space-between; }  
    &::-webkit-scrollbar {}
    .MuiTreeItem-root {
      & { border: 1px solid #E6E6E6; border-radius: 4px; }
      .navigator-list-container { 
        & { display: flex; justify-content: space-between; align-items: center; padding: 0 10px; margin: 5px 0; border-radius: 4px; height: 30px; }
      }
    }
    .normal-navigator-list {
      & { margin: 0; height: auto; display: block; }
      .nav-list-heading-and-more-options-container { display: flex; align-items: center; justify-content: space-between; margin: auto; padding: 15px; padding: 2px 10px; border: 1px solid black; }
      .more-options { & { display: flex; align-items: center; } 
        .edit-icon { cursor: pointer; }
        .delete-icon { cursor: pointer; }
      }
      .navigator-list-heading-container {
        & { display: flex; align-items: center; gap: 5px; }
        .navigator-list-bullet-icon {
          img { width: 16px; height: 16px; }
        }
        .navigator-list-text {
          & { display: flex; align-items: center; } 
        }
        .expand-nav-list { cursor: pointer; background-color: #fff; padding: 0; }
      }
    }
  }
  .nested-list-view {
    .root-parent { border: 1px solid #000; border-top: none; }
    .root-parent:nth-child(1) { border-top: 1px solid #000; }
    .navigator-list { 
      & { overflow: visible; margin-left: 0; height: auto; display: grid; cursor: pointer; }
      .nav-list-heading-and-more-options-container {
        & { display: flex; align-items: center; justify-content: space-between; padding: 0 10px; box-sizing: border-box; background: #fff; }
        .more-options {
          & { display: flex; align-items: center; gap: 5px; }
          svg { font-size: 1rem; }
          img { width: 14px; }
        }
      }
    }
    .navigator-list-child { margin-left: 10px; width: 100%; }
    .navigator-list-heading-container {
      & { display: flex; align-items: center; gap: 5px; }
      .navigator-list-bullet-icon {
        & { width: 24px; height: 27px; display: flex; align-items: center; }
        img { width: 16px; height: 16x; }
      }
      .navigator-list-text {
        & { display: flex; align-items: center; } 
      }
      .expand-nav-list { cursor: pointer; background-color: #fff; padding: 0; }
    }
  }
  .navigator-breadcrumbs { & { display: flex; align-items: center; gap: 10px; }
    h3 { cursor: pointer; }
  }
  .navigator-list { 
    .contenttype-container {
      & {  cursor: pointer; display: flex; justify-content: space-between; padding: 10px; box-sizing: content-box; margin: 10px 0; background: #212a42; color: white; }
      p { margin: 0; }
    }
  }
}

.duplicate-style-modal-parent {
  & { display: flex; flex-direction: column; gap: 40px; }
  .question-close-icon-container {
    & { display: flex; align-items: center; justify-content: space-between; }
    h2 { font-size: 1.1rem; font-family: $fontLato; }
    .close-icon { 
     svg { font-size: 2rem; }
    }
  }

  .delete-cancel-btn-container { 
    & { display: flex; align-items: center; gap: 20px; }
    h3 { font-size: 1.5rem; margin-top: 0; }
    button { padding: 10px; background-color: #122845; border-radius: 13px; color: #fff; font-size: 18px; width: 100%; font-weight: bold; text-transform: uppercase; }
  }
}

.crop-container {
  & { width: 900px; height: 550px; display: flex; flex-direction: column; align-items: center; justify-content: center;}
  .title { width: 100%; display: flex; align-items: center; justify-content: space-between; margin: 0 15px; margin-right: 25px;
    h2 { margin: 0; color: #4D5865; }
    .MuiSvgIcon-root { color: #929292; border: 2px solid #929292; border-radius: 10px; cursor: pointer;}
  }
  .easy-crop {
    & { margin: 10px 0; position: relative; height: 72%; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    .d-n-d { 
      & { height: 70%; width: 90%; border: 2px dashed #929292; border-radius: 25px; display: flex; align-items: center; justify-content: center;}
      .MuiButtonBase-root { height: 36px; background: #212A42; color: #fff; padding: 0 15px; border-radius: 5px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; }
    }
    .reactEasyCrop_Container {
      & { height: 100%; border-radius: 10px; }
    }
  }

  // .easy-crop-header {
  //   & { height: 72px; width: 184px; }
  // }

  .crop-btns {
    & { width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .slider {
      & { margin-left: 15px; width: 35%; }
      h3 { margin: 0; color: #4D5865; }
      .MuiSlider-root {
        & {  color: #212A42; height: 6px; }
      }
    }
    .MuiButtonBase-root {
      & { width: 180px; height: 36px; background: #212A42; color: #fff; border-radius: 5px; font-family: 'Montserrat'; font-style: normal; font-weight: 700; }
      .MuiCircularProgress-root { height: 30px !important; width: 30px !important; }
      .MuiCircularProgress-root.uploading { height: 18px !important; width: 18px !important; }
    }
    .Mui-disabled { background: #efefef; color: #929292; }
  }
}

.add-image-modal {
  .modal-heading {
    & { display: flex; justify-content: flex-end; }
  }

  .dropzone-content { & { text-align: center; }
    p { font-size: 14px; font-family: $fontmontserrat; font-weight: 600; text-transform: uppercase; }
    p:first-child { font-weight: 700; color: #313131; }
  }

  .easy-crop {
    .d-n-d { 
      & { padding: 5px 10px; height: auto; width: 90%; border: 2px dashed #92929292; border-radius: 5px; }
      .MuiButtonBase-root { padding: 5px 10px; }
    }
    .reactEasyCrop_Container {
      & { height: 100%; border-radius: 10px; }
    }
  }
  // .reactEasyCrop_CropArea { width: 100% !important; height: 100% !important; }
}

.email {
  & { padding-bottom: 10px; };
  .email-header { background-color: #F4F2EC; padding-left: 25px; padding-top: 5px; margin: 0px;}
  .email-header h1 { font-size: 24px; color: #57606F; line-height: 25px; font-family: $fontbutler; margin:10px auto 0; }  
    .email-link-container {
         p { font-size: 18px; color: #5B5B5B; margin: 5px auto; } 
       .email-link { color: #5B5B5B; } 
    } 
  .email-container {
    & { padding-top: 15px;}
    .box { margin-left: 25px;}
    .MuiDataGrid-columnHeaders { background-color: #F1F1F1 ; height: 56px; }
    .MuiTab-wrapper { color: #57606F; font-size: 14px; font-family: $fontmontserrat; }
    .Mui-selected { font-weight: 600; }
    .MuiDataGrid-columnHeaderTitleContainer {justify-content: start; }
    .MuiDataGrid-columnHeaderTitle { color: #5B5B5B; font-size: 15px; font-weight: 400; font-family: $fontmontserrat; }
    .MuiDataGrid-cell {justify-content: start; }
    .MuiDataGrid-cellContent { color: #0F131D; font-weight: 500; font-size: 14px; font-family: $fontmontserrat; white-space:break-spaces;}
    .MuiDataGrid-cell--textRight {justify-content: end; gap: 15px; }
    // .search-bar {background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; border: 1px solid #BEBEBE; border-radius: 4px; margin:16px; font-family: $fontmontserrat; max-width: 187px;margin-top: 20px; margin-left: 20px;} 
      .search-bar { & { position: relative; width: 250px; }
      input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
      .search-field { background-color: #FBF9F3; color: #979797; padding: 10px; font-size: 16px; border: 1px solid #CBCBCB; border-radius: 3px;  font-family: $fontmontserrat; width: 250px; }
      .clear-search-icon { position: absolute; right: 8px; bottom: 10px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
    }
    .email-type { font-family: $fontmontserrat; }
    .grid { border: 1px solid #E6E6E6; border-radius: 8px; height: 100%; margin: 20px; }
    .MuiDataGrid-row { background-color: #ffffff; }
    .MuiDataGrid-virtualScrollerContent {background-color: #FFFFFF; border-radius: 8px; }
    .MuiDataGrid-footerContainer{ display: none; }
    .btn-container{
      &{display: flex; justify-content: space-between; align-items: center; margin: 20px;}
      .create-btn{
        &{width: 107px;height: 40px;background-color: #122845; border-radius: 4px; color: #fff; font-size: 16px; font-weight: bold; margin-right: 20px; cursor: pointer; border: none; font-family:$fontmontserrat ; }
      }
    }
    .MuiBox-root{padding: 0px; border-bottom: 1px solid #EDEDED;}
    .email-notification-table{
       .MuiDataGrid-cell {
       &{ justify-content: start; padding-left: 15px;}
       }
       .MuiDataGrid-columnHeaderTitleContainer {justify-content: start; padding-left: 5px; }
       .email-name{
        &{display: flex; flex-direction: column;}
        div{
          &{color: #0F131D;font-weight: 600;font-size: 14px;font-family: "Montserrat", sans-serif;}
        }
        }
        img{cursor: pointer;}
    }
  }
}



.edit-notification-container ,.create-notification-container{
  .modal-content{
    &{position: absolute;min-width: 674px; max-height: 854px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white;}
     .main-content {
      & {padding: 30px; font-family: $fontmontserrat;}
      .heading-container{ display: flex; justify-content: space-between; align-items: center;}
      .heading{
       & { letter-spacing: 0px;color: rgba(33, 33, 33, 1); font-weight: bold; font-size: 25px;}
      }
     .loader-section-uploaing{position: absolute; top: 53%;  left: 50%;  transform: translate(-50%, -50%);  padding: 10px;  border-radius: 5px;    display: flex;    align-items: center;    justify-content: center;   flex-direction: column;   z-index: 11;
     }
      .label{
        & { letter-spacing: 0px; color: rgba(33, 33, 33, 1); font-size: 16px; font-weight:500; margin-bottom: 8px; font-family: $fontmontserrat;}
      }
     .items{
     & {  margin-top: 10px;}
     .text-box{
       width: -webkit-fill-available;  padding: 15px; border: 1px solid rgba(190, 190, 190, 1); border-radius: 4px; font-size: 16px; font-family: $fontmontserrat;
     }
     .MuiFormGroup-root{flex-direction: row;}
     .MuiRadio-colorSecondary.Mui-checked{color: rgba(18, 40, 69, 1);}
     .MuiTypography-body1{font-size: 16px; color: rgba(33, 33, 33, 1); font-family: $fontmontserrat;}
     }
     .create-btn-submit{
        & { width:100%;height: 50px;background-color: #122845; border-radius: 4px; color: #fff; font-size: 16px; font-weight: bold; margin-top: 50px;font-family: $fontmontserrat; border: none; text-transform: uppercase; cursor: pointer;}
      }
      .MuiCircularProgress-colorPrimary {height: 30px !important; width: 30px !important; text-align: center; }

     img{cursor: pointer;}
    .upload-container {
      & { position: relative; display: inline-block; max-height: 180px; width: 100%; }
        input[type="file"] { opacity: 0; position: absolute; top: 0; left: 0; height: 100%; width: 100%; cursor: pointer;}
        img { height: 100%; width: auto; display: block; max-width: 594px; max-height: 176px; }
      }
    }
  }
 .edit-notification-modal { min-height: 844px; }
}


.modal{
  .delete-popup-content{
  &{position: absolute;max-width: 400px;min-height: 256px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; font-family: $fontmontserrat;border-radius: 5px;}
    .main-content {
      & {padding: 24px; font-family: $fontmontserrat;}
      .heading-container{
      &{display: flex; justify-content: space-between; align-items: start; margin-bottom: 24px;}
      .heading{
      &{ letter-spacing: 1px; color: #000000; font-weight: 600; font-size: 20px;white-space: break-spaces;}
      }
      img{cursor: pointer;}
     }
     .delete-btn{
      &{width: 352px; height: 47px; background-color: #122845; border-radius: 5px; color: #fff; font-size: 16px; font-weight: 500; margin: 16px auto; cursor: pointer;font-family: $fontmontserrat; border: none; text-transform: uppercase;}
     } 
    .MuiCircularProgress-colorPrimary {height: 30px !important;width: 30px !important;text-align: center;color: white;}
    .cancel-btn{
      &{width: 352px; height: 47px; background-color: #fff; border-radius: 5px; color: #122845; font-size: 16px; font-weight: 500; border: 1px solid #122845; cursor: pointer;font-family: $fontmontserrat; text-transform: uppercase;}
     }
  }
  }

  .modal-input-field {
    .MuiTextField-root{ margin-bottom: 20px;}
    button { width: 100%; background-color: #122845; padding: 10px 30px; border-radius: 5px; color: #ffffff; font-size: 16px; text-transform: uppercase; cursor: pointer; }
  }
  .checkbox-container { margin-bottom: 1rem; }
}

.edithtml-popup-modal{
     .edithtml-popup-content{
     &{position: absolute;min-width: 1088px; max-height:680px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; font-family: $fontmontserrat;border-radius: 11px;border: 1px solid #7FB6BE;}
     .main-content {
      & {padding: 30px; font-family: $fontmontserrat;}
         .heading-container{
         &{display: flex; justify-content: space-between; align-items: center; margin-bottom: 24px;}
          .heading{
          &{ letter-spacing: 0px; color: #000000; font-weight: bold; font-size: 25px; font-family: $fontmontserrat; }
          }
          img{cursor: pointer;}
         }
         .label{color: #212121; font-weight:500; font-size: 16px; margin: 5px auto;font-family: $fontmontserrat; }
         textarea{width: 100%;  height: 440px !important; border: 1px solid #BEBEBE; border-radius: 4px; font-family: $fontmontserrat; }
         .edit-btn{
          &{width: 100%;height: 50px; background-color: #122845; color: #ffffff;border-radius: 4px; margin: 48px auto; font-size: 16px; font-weight: bold; border: none; cursor: pointer;text-transform: uppercase; font-family: $fontmontserrat; }
         }
        .upload-container {
       &{ position: relative; display: inline-block; }
       input[type="file"] { opacity: 0; position: absolute; top: 0; left: 0; height: 100%; width: 100%; cursor: pointer;}
        img { height: 100%; width: auto; display: block; max-width: 594px; max-height: 176px; }
      }
      .MuiCircularProgress-colorPrimary {height: 30px !important;width: 30px !important;text-align: center;color: white;}

      }
  }
}

.send-popup-modal{
 .send-popup-content{
  &{position: absolute;min-width: 534px; width: 100%; min-height: 854px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; font-family: $fontmontserrat;border-radius: 5px;}
    .main-content {
      & {padding: 24px; font-family: $fontmontserrat;}
      .heading-container{
      &{display: flex; justify-content: space-between; align-items: start; margin-bottom: 35px;}
      .heading{
      &{ letter-spacing: 1px; color: #000000; font-weight: 600; font-size: 20px; font-family: $fontmontserrat; }
      }
      img{cursor: pointer; height: 100%;}
     }
     label{color: #212121; font-size: 16px; font-weight: 500; margin-bottom: 5px; font-family: $fontmontserrat; } 
     input{width: 95%;height: auto;  color: #000000;  padding: 10px;  font-size: 16px;   border: 1px solid #CBCBCB;   border-radius: 3px; font-family: "Montserrat", sans-serif;}
      input[type="text"] {-webkit-appearance: none; }
      .form-group{
      &{
        display: flex; flex-direction: column; margin-bottom: 15px;
      }
     }
      .MuiCircularProgress-colorPrimary {height: 30px !important;width: 30px !important;text-align: center;color: white;}

     .delete-btn{
      &{width: 100%; height: 50px; background-color: #122845; border-radius: 5px; color: #fff; font-size: 14px; font-weight: 500; margin: 16px auto; cursor: pointer; margin-top: 35px;font-family: $fontmontserrat; border: none;text-transform: uppercase;}
     } 
     .cancel-btn{
      &{width: 100%; height: 50px; background-color: #fff; border-radius: 5px; color: #122845; font-size: 14px; font-weight: 500; border: 1px solid #122845; cursor: pointer;font-family: $fontmontserrat; text-transform: uppercase; }
     }
     .email_chip{
       & { font-family: $fontmontserrat; text-transform:lowercase; }
       input[type=text] {
        border: none;
        height: 8px;
       }
      }
    .MuiChip-label{text-transform: lowercase; }
  }
}
}


.assesment-container{
   &{ position: fixed;  left: 0;  top: 0;  width: 100%;height: 100%;transform: scale(1.1); transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; z-index: 3;background-color: rgba(0, 0, 0, 0.5);
  }  .modal-content{
    &{position: absolute;min-width: 674px; min-height: 666px; top: 50%; left: 50%; transform: translate(-50%, -50%); background-color: white; border: 1px solid #7FB6BE;border-radius: 11px;}
     .main-content {
      & {padding: 40px; font-family: $fontmontserrat;}
      .heading-container{ display: flex; justify-content: space-between; align-items: center;}
     .heading{
      &{letter-spacing: 0px;color: rgba(33, 33, 33, 1); font-weight: bold; font-size: 25px;}
     }
      .label{
      &{letter-spacing: 0px; color: rgba(33, 33, 33, 1); font-size: 17px; font-weight:500; margin-bottom: 4px; font-family: $fontmontserrat;
      }
     }
     .items{
     &{  margin-top: 20px;}
     .text-box{
     &{ width: 96%;  padding: 10px; border: 1px solid rgba(190, 190, 190, 1); border-radius: 4px; font-size: 15px;}
     }
     .text-select{
     &{ width: 100%;  padding: 10px; border: 1px solid rgba(190, 190, 190, 1); border-radius: 4px; font-size: 15px;}

     }
     .MuiFormGroup-root{flex-direction: row;}
     .MuiRadio-colorSecondary.Mui-checked{color: rgba(18, 40, 69, 1);}
     .MuiTypography-body1{font-size: 16px; color: rgba(33, 33, 33, 1); font-family: $fontmontserrat;}
     }
     .create-btn-submit{
        &{width: 594px;height: 56px;background-color: #122845; border-radius: 11px; color: #fff; font-size: 16px; font-weight: bold; margin-top: 50px;font-family: $fontmontserrat; border: none; cursor: pointer;}

     }
     .MuiOutlinedInput-root{max-height: 40px;}
     img{cursor: pointer;}
       .upload-container {
      &{ position: relative; display: inline-block; }
       input[type="file"] { opacity: 0; position: absolute; top: 0; left: 0; height: 100%; width: 100%; cursor: pointer;}
        img { height: 100%; width: auto; display: block; max-width: 594px; max-height: 176px; cursor: pointer; }
      }
   
    }
  }
}






.preview{

    &{ position: fixed;  left: 0;  top: 0;  width: 100%;height: 100%;transform: scale(1.1); transition: visibility 0s linear 0.25s, opacity 0.25s 0s, transform 0.25s; z-index: 3;
  }
  .modal-content{
    &{position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); }
     .main-content {
      img{ height: 200px;}
          .heading-container{
      &{display: flex; justify-content: flex-end; align-items: center; }
      img{height: 20px; cursor: pointer;}
          }
     
    }
  }
}















// main h1 {margin: 10px 0 10px 0;font-size: 2.5em;font-weight: 800;text-transform: uppercase;color: #3c6382;}

// Main container and their styles
// -------------------------------
main {

  // & { width: 1}

    // Main toolbar
    .pagetitle {background: transparent !important;width: 100%;}
    .mat-toolbar {background: transparent !important;}
    .mat-toolbar-row h1 {margin: 10px 0 10px 0;font-size: 1.5em;font-weight: 800;text-transform: uppercase;color: $titlecolor1;font-family: $mainfont;}
    .mat-toolbar-row button {font-weight: 800;font-family: $mainfont;text-transform: uppercase;margin-right: 5px;}

    .table-container {display: flex;flex-direction: column;min-width: 300px;background: $themecolor4;border-top: 2px solid $themecolor1;}

    // Mat Table
    // ---------
    .mat-table {font-family: $mainfont;overflow: auto;max-height: 80vh;}
    .mat-header-row {position: sticky;top: 0;background-color: #fff;z-index: 1000;}
    .mat-header-cell {font-size: 1em;color: $themecolor1;font-family: $mainfont;text-transform: uppercase;font-weight: 800;}
    .mat-menu-item {font-family: $mainfont;letter-spacing: 0;font-size: 12px;padding: 0 8px !important;height: auto !important;line-height: 36px !important;margin-bottom: 0;}

    .draft {background: #e55039;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .running {background: #78e08f;color: #fff;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}
    .closed {background: none;color: #78e08f;font-weight: 800;padding: 2px 5px;border-radius: 2px;-moz-border-radius: 2px;-webkit-border-radius: 2px;}

}

.mat-column-responses {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-status {flex: 0 0 160px !important;text-align: center !important;}
.mat-column-tools {flex: 0 0 80px !important;text-align: right;}



app-lightbox { display: flex; justify-content: center; position: fixed;  width: 100%; height: 100%;
  background: rgba(0,0,0,0.5);  z-index: 10000; font-family: $mainfont; left: 0px; overflow-y: scroll; }

  .openlightbox { opacity: 1 !important; }

  .lightbox {
    & { position: absolute; border-radius: 30px; text-align: center; padding: 0px; background: #FFF; max-width: 680px; z-index: 10001; min-height: 100px; top: 64px; font-family: $mainfont;  left: 50%; transform: translate(-50%, 0px); }

    h1 { color: #1F4E96; font-size: 1.5em; margin: 0px;
    padding: 20px 0px; border-top-right-radius: 30px; border-top-left-radius: 30px; }

    h2 { font-size: 1.4em;color: #1F4E96; font-weight: 800; font-family: "proxima-nova", sans-serif; }

    .lightboxfooter { justify-content: space-between !important; padding: 15px 30px; }
    .lightboxfooter button { font-family: $mainfont; background: #f1f2f6; font-weight: 600; }

    form { margin-bottom: 0px; }
    .mat-form-field { width: 100%; }
  }

  .defaultLightbox > div { padding: 30px;
    font-family: $mainfont;
    &:last-of-type { display: flex;  justify-content: flex-end; }
    > p { text-align: justify; }
  }



// Snackbar container
// ----------------
.mat-snack-bar-container {margin: 20px !important;}
.mat-snack-bar-container-failure {background-color: $bg-failure;}
.mat-snack-bar-container-success {background-color: $bg-success;}
.mat-snack-bar-container-pending {background-color: $bg-pending;color: #fff;}

// ========================= Smiler CSS ============================== //

// =================== Global CSS ===================



// theme colors
$smilercolor1: #FFEB33;

// background
$bgmain: #f6f7fa; // body

// form
$formtext: #1F4E96;
$formbg: #eff5ff;

// classes
body { background-color: $bgmain; font-family: $mainfont; }
.full-frame { max-width:1440px; margin:0 70px; width: calc(100% - 140px); }
.medium-frame { max-width:991px; margin:0 70px; width: calc(100% - 140px); }
.cdk-overlay-container { z-index: 10002; }

// spinner
.matspinner {display: flex;justify-content: center;align-items: center;min-height: 50vh;}

// buttons
.yellowBtn { background-color: $smilercolor1 !important; color:#292F36 !important; font-family: $mainfont; }
.outlineBtn { background-color: none !important; color: #292F36 !important; border: 1px solid #292F36 !important; font-family: $mainfont; }
.yellowBtn.mat-button-disabled { opacity: 0.5; }
.plainBtn { background-color: #ffffff !important; color:#007C89 !important; font-family: $mainfont; }
.plainBtn.mat-button-disabled { opacity: 0.5; }
.btn-large { box-shadow: none !important; width: 100%; border-radius: 12px !important; height: 48px; font-size: 16px; font-weight: 700; }
.btn-small { box-shadow: none !important; min-width: 120px !important; border-radius: 12px !important; height: 48px; font-size: 16px; font-weight: 700; }

.smiler-button-loader {
  & { transition: 0.1s !important; }
  &.smiler-button-loading { min-width: 50px !important; height: 50px !important; border-radius: 100% !important; padding: 0px !important; }
  .submit-loader { width: 40px; }
}

// form field
.form-field {
  .field-label { color: #292F36; font-size: 16px; font-weight: 500; }
  .mat-form-field { width:100%; }
  input[type="password"], input[type="text"] { border: none !important; padding: 0px; height: auto; background-color: transparent; }
  .mat-form-field {
    & { width:100%; font-family: $mainfont; }
    // .mat-form-field-wrapper { margin: 0; padding: 0; }
    .mat-form-field-infix { padding: 12px 0 20px 0; border-top-width: 12px; }
    .mat-form-field-outline-start, .mat-form-field-outline-end { border-width: 0; }
     input::placeholder { font-size: 14px; color: #D0D5DB; font-family: $mainfont; }
     input { font-size: 14px; color: $formtext !important; font-family: $mainfont; }
     textarea { border:none;  font-size: 14px; color: $formtext; }
     textarea::placeholder { color: #D0D5DB; }
     .mat-form-field-outline { background: #F2F4FC; border-radius: 12px; }
     .mat-form-field-outline-thick .mat-form-field-outline-start,
     .mat-form-field-outline-thick .mat-form-field-outline-end,
     .mat-form-field-outline-thick .mat-form-field-outline-gap {
      border-width: 0px ;
      }
      .mat-form-field-suffix { top: 7px; }
      .mat-form-field-suffix .material-icons { color: #E2E7F4; }
      .mat-form-field-suffix .material-icons.valid { color: #718194; }
      .mat-error { font-weight: 600; }
   }
}

// checkbox
.circle-checkbox {
  .mat-checkbox-frame { border-color: #C7C9D9; border-radius: 100%; border-width: 1px; background-color: #F2F2F5 ; }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, &.mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #FFFD00; border-radius: 100%; }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  { background: #FFFD00; }
  .mat-checkbox-checkmark-path { stroke: #292F36 !important; }
  .mat-checkbox-label { font-weight: 400; color: #4D5865; font-size: 14px; }
}

.circle-large-checkbox {
  .mat-checkbox-frame { border-color: #007C89; border-radius: 100%; border-width: 2px; }
  &.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, &.mat-checkbox-checked.mat-accent .mat-checkbox-background { background-color: #007C89; border-radius: 100%; }
  &.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element, &.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element  { background: #007C89; }
  .mat-checkbox-checkmark-path { stroke: #fff !important; }
  .mat-checkbox-inner-container { height: 24px; width: 24px; }
}

// slider
.yellow-slider.mat-slider-horizontal {
  & { padding: 0px; }
  .mat-slider-wrapper, .mat-slider-track-wrapper, .mat-slider-track-background, .mat-slider-ticks-container, .mat-slider-ticks { height: 4px; }
  .mat-slider-track-fill { height: 6px; }
  .mat-slider-track-fill, .mat-slider-thumb, .mat-slider-thumb-label { background-color: #FFDB15; }
  .mat-slider-thumb { width: 30px; height: 30px; bottom: -15px; }
  .mat-slider-track-background { background-color: #FFF7AD; }
  .mat-slider-track-wrapper { border-radius: 4px; }
}

// =================== Global CSS ===================

// =================== Header ===================

.header {
  & { position: fixed; background: #fff; z-index: 99; }
  .header-toolbar {
    & { padding: 0; justify-content: space-between; }
    // logo
    img.logo { margin-top: -10px; }
    // search
    .mat-form-field {
     & {  background: $formbg; width: 320px; border-radius: 5px; }
     .mat-form-field-wrapper { margin: 0; padding: 0; }
     .mat-form-field-infix { padding: 2px 0 14px 0; border-top-width: 10px; }
     .mat-form-field-outline-start, .mat-form-field-outline-end { border-width: 0; }
      input , input::placeholder { font-size: 14px; color: $formtext; font-family: $mulish; }
      .input-search { margin-right: 5px; }
    }
    // header right
    .header-right {
      & { display: flex; align-items: center; flex-wrap: wrap; }
     img { margin: 0px 12px; cursor: pointer; }
    }
  }
  .full-frame { width: calc(100% - 140px); }
}
.header-spacer { padding-top: 64px; }
.public-header {
  & { text-align: right; margin-top: 30px; }
  img { width: 50px; }
}
// =================== Header ===================

// =================== layout ===================

.app-wrapper {
  & { display: flex; flex-wrap: wrap; padding-top: 30px; }
  app-menu { flex: 0 0 300px; margin-right: 15px; }
  router-outlet + * { flex: 1 0 auto; }
}

// ========== menu ==========
.layout-menu {
  & { width: 250px; }
  .user-info {
    & { display: flex; flex-wrap: wrap; align-items: center; border-bottom: 1px solid #C6C6C6; padding-bottom: 15px; }
    img { max-width: 52px; border-radius: 40%; }
    .user-name { margin-left: 10px; font-size: 14px; flex: 1 0 auto; }
    .menu-icon {
      & { display: flex; flex-wrap: wrap; cursor: pointer; }
      span { width: 3px; height: 3px; border-radius: 100%; border: 1px solid #3483FA; margin: 0px 1px; }
    }
  }
  .smiler-menu {
    & { margin-top: 15px; }
    .menu-item {
      & { display: flex; flex-wrap: wrap; align-items: center; margin: 15px 0px; cursor: pointer; }
      .menu-text { margin-left: 15px; color:#656565; font-size: 16px; flex: 1 0 auto; }
      .menu-icon { display: flex; align-items: center; justify-content: center; width: 45px; height: 45px; border-radius: 100%; background: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%); box-shadow: 0px 12px 40px rgba(202, 202, 238, 0.4); }
      .menu-notifications { color: #1F4E96; font-size: 12px; background-color: #FFFD00; padding: 2px 8px; border-radius: 10px; font-weight: 700; }
      &.active {
        .menu-icon { background: linear-gradient(90deg, #FFFD00 0%, #FFFFB3 100%); box-shadow: 0px 8px 16px rgba(255, 253, 0, 0.3); }
        .menu-text { font-weight: 600; color: #323232; }
      }
    }
  }
}
// ========== menu ==========

// =================== layout ===================

// =================== login ===================

// =================== login ===================

// =================== register ===================
.smiler-register {
  & { display: flex; flex-wrap: wrap; align-items: center; margin-bottom: 60px; }
  .register-left {
    & { flex: 0 0 45%; margin-top: -60px; }
    .register-wrapper { box-shadow:inset 0px 20px 50px rgba(222, 226 ,230 ,0.25); border-radius: 24px; background-color: #fff; padding: 60px 60px; }
    .form-heading { color: #718194; font-size: 32px; font-weight: 600; line-height: 32px; }
    .form-wrapper {
      & { margin: 70px 0px 0px; }
      form { margin-bottom: 0px; }
    }
    .yellowBtn { margin-top: 30px; }
    .name-wrapper {
      & { display: flex; flex-wrap: wrap; }
      .form-field { flex: 0 0 calc(50% - 5px); width: calc(50% - 5px); }
      .first-name { margin-right: 5px; }
      .last-name { margin-left: 5px; }
    }
  }
  .register-right {
    & { flex: 0 0 55%; text-align: center; }
    .right-title { color: #4D5865; font-size: 20px; font-weight: 300; }
  }
}
// =================== register ===================

// ========================= Smiler CSS ============================== //


.main {
  
  & { max-width: calc(100vw - 300px); transition: .5s; }
  
  .breadcrumb-navlink { cursor: pointer; }
  .statistics {
    
    .stats-container { 
      & { width: 100%; min-height: 170px; display: flex; justify-content: space-between; padding: 10px 0; }
      .stats-box { 
        & { flex: 0 1 19%; display: flex; flex-direction: column; justify-content: space-between; background: #fff; text-align: center; font-size: 14px; }
        h2 { padding-bottom: 0; margin: 0; padding-top: 10px; }
        .count { font-size: 50px; padding-top: 25px; }
        .info { font-size: 12px; padding-bottom: 20px; }
      }
    }
    
  }
  
  .questions {
    .section-name { text-transform: capitalize; }
  }
  
}

.main-closed { width: 92%; max-width: calc(100vw - 90px); flex: none; margin-left: 92px; }


// ========================= Drawers ============================== //

.add-question-drawer {
  .form-control-add-question { & { margin: 0 5px; font-size: 18px; }
  .pageid-pagename-container { width: 90%; display: flex; justify-content: space-between; }
  .page-type-loading-spinner { max-width: 303px; width: 90%; display: flex; align-items: center; border: 1px solid #BEBEBE; padding: 11px 15px; border-radius: 5px; }
    .MuiOutlinedInput-multiline { padding: 10.5px 14px; }
    label { text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; margin-bottom: 8px; }
    .MuiSelect-select { padding: 10px; box-sizing: border-box; height: 40px; }
  }
  .question-mandatory-toggle { 
    & { margin: 0 5px; display: flex; flex-direction: row; gap: 16px; align-items: center; text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; margin-top: 32px; }
    .MuiSwitch-switchBase { top: 7%; left: 5%; }
    .mandatory-switch .Mui-checked { color: #8f51a4; }
  }
  .question-applicable-toggle { 
    & { margin: 0 5px; display: flex; flex-direction: row; gap: 16px; align-items: center; text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; margin-top: 32px; }
    .MuiSwitch-switchBase { top: 7%; left: 5%; }
    .Mui-checked { color: #8f51a4 !important; }
  }
  .add-options-and-heading-container { 
    & { display: flex; align-items: center; justify-content: space-between; margin: 24px 0 54px; }
    h3 { align-items: center; text-align: left; font: normal normal 600 14px/18px $fontmontserrat; letter-spacing: 0px; color: #212121; }
  }
  .cancel-btn-and-submit-btn {
    & { display: flex; gap: 20px; justify-content: flex-end; }
    .add-question-btn { background-color: #122845; text-align: left; font: normal normal bold 16px/21px $fontLato; letter-spacing: 0px; color: #FFFFFF; width: max-content; padding: 10px 30px; margin-bottom: 100px; border: 2px solid #122845; }
    .cancel-btn { text-align: left; font: normal normal bold 16px/21px $fontLato; letter-spacing: 0px; color: #122845; width: max-content; padding: 10px 30px; margin-bottom: 100px; border: 2px solid #122845; }
    .add-question-btn:hover { color: #122845; background: transparent; }
  }
  .option-btn { 
    & { border: 2px solid #122845; border-radius: 4px; margin: 10px 0; display: flex; align-items: center; text-align: left; font: normal normal bold 16px/21px Lato; letter-spacing: 0px;  }
    .MuiButton-label {
      & { display: flex; align-items: center; gap: 8px; }
      .add-icon {
        & { display: flex; align-items: center; }
        path { fill: #122845; }
      }
    }
    
  }
  .delete-icon { cursor: pointer; margin-top: 30px; }
}
  // .section-select { width: 120px; }
// .section-name { text-transform: capitalize; }

// ========================== Pages ========================== // 

.engagement { & { padding: 15px 30px; font-family: $fontmontserrat; }
  h1 { margin: 10px auto 5px; }
  .engagement-link { display: block; margin: 0 auto 10px; font-size: 18px; color: #5B5B5B; }
  .tab-container { & { display: flex; flex-direction: row; }
    .box { border-bottom: 1px solid #EDEDED; }
    .tab-title { text-transform: capitalize; font-family: $fontmontserrat; font-weight: 500; color: #343333; }
    .tab-title.Mui-selected { font-weight: 600; color: #1F2A44; }
  }
  .grid {
    .MuiBox-root { padding: 0; }
  }
  

  .subscription-grid-container { & { min-height: 80vh; font-family: $fontmontserrat; font-size: 16px; max-width: 100%; margin: 15px auto; }
    // .MuiDataGrid-root {  }
    .MuiDataGrid-main .MuiDataGrid-columnHeaderTitle { font-weight: 700; font-family: "Montserrat", sans-serif; font-size: 15px; }
  }
  .subscribers-table-container { & { background-color: #FBF9F3; margin-top: 30px; }
    .subscribers-table { & { width: 95%; max-width: 100%; margin: 0 auto; background-color: #ffffff; font-family: $fontbutler; }
    th, td { font-size: 18px; font-family: $fontmontserrat; }
  }
}
  .red { background-color: #DC4A37; }
  .orange { background-color: #e67e22; }
  .green { background-color: #73B76F; }
  .chip { width: 15ch; color: #ffffff; }
  .chip .MuiChip-label { font-size: 14px; color: #ffffff; }
}


main .see-more-btn, main .bulk-delete-btn { background-color: #212A42; color: #ffffff; font-weight: 500; font-size: 14px; letter-spacing: 1px; padding: 6px 12px; }
main .see-more-btn:hover { background-color: #B9B9B9; border: 1px solid #343333; }
main .bulk-delete-btn:hover { background-color: #212A42; color: #ffffff; box-shadow: 1px 1px 5px 1px #7f7f7f; }
main .see-more-btn { font-family: $fontmontserrat; background-color: #D9D9D9; color: #1F2A44; font-weight: 600; letter-spacing: 0; }
main .bulk-delete-btn { padding: 8px 12px; margin: 0 10px 15px; }
main .call-btn.blue { color: cornflowerblue; color: #999999; }
main .call-btn.done { color: #59945A; color: #999999; }
main .call-btn.hide { display: none; }
// main .call-btn.hide { color: crimson; }
// main .call-btn { cursor: pointer; }

// Universal Styles 
.loader-section { display: flex; min-height: 300px; justify-content: center; align-items: center; }
.loader-section-drawer { display: flex; min-height: 300px; width: 70vw; justify-content: center; align-items: center; }
ul.unstyled { list-style: none; padding: 0; }
ul.unstyled li { & { padding: 5px 0; text-align: right; }
  input { padding: 5px; }
}
.ql-toolbar { position: absolute; top: -115px; background-color: #212121; width: 330px; margin: 0 auto; left: -165px; }
.editor .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow { border-bottom: 6px solid #212121; top: -75px; transform: rotate(180deg); }
.tox.tox-tinymce.tox-tinymce-inline { max-width: 480px; }


// =========== Assessment Builder Page =============== 



.input-field-heading { & { display: flex; justify-content: space-between; } 
   .field-heading { font-size: 26px; color: #212121; font-family: $fontmontserrat; }   
}
.field-list { & {}
  .field-select-type { & { display: flex; justify-content: space-between; } 
  .MuiInput-underline::before { border-bottom: none; content: ""; transition: none; }
  .MuiInput-underline::after { border-bottom: none; content: "";  transition: none; }
  .MuiInput-underline:hover:not(.Mui-disabled)::before { border-bottom: none; }
  .MuiInput-underline:hover:not(.Mui-disabled)::after { border-bottom: none; }

  .MuiSelect-select  { padding-left: 12px; color: #000000; font-size: 18px; font-family: $fontmontserrat; } 
  
}
  .input-labels {& { margin-top: 15px; display: flex; justify-content: space-between; }
    .MuiFormControl-root { border: 1px solid #BEBEBE; border-radius: 8px; width: 270px; }
    .MuiInput-input { padding-left: 12px; font-size: 18px; font-family: $fontmontserrat; }
  }

  .dropdown {& {display: flex; flex-wrap: wrap; justify-content: center; border-radius: 8px; margin-top: 25px; }
    .dropdown-item { border: 1px solid #BEBEBE; max-width: 279px; display: flex; height: 52px; }
    .dropdown-item-name-active {border: 1px solid #BEBEBE; height: 32px; border-radius: 8px; margin: 10px; justify-content: center; }
    .MuiInput-input { padding-left: 10px; font-size: 18px; font-family: $fontmontserrat; margin-top: 6px; }
    .Mui-disabled { color: #000; margin-top: 5px; }
    .edit-save-button { background-color: #122845; border-radius: 8px; color: #ffffff; height: 32px; margin: 10px 10px 0px 0px; }
  }

  .members-button {& { display: flex; margin-top: 15px; }
    .MuiFormControlLabel { }
}
}

.invite { & {}
  .MuiInputBase-input{ padding-top: 8px; padding-left: 12px; font-family: Barlow, Nunito, san-serif, Lato; }

}


 //DELETE MODAL//
//  .delete-container { & { }
 .MuiBackdrop-root { background-color: rgba(0, 0, 0, 0.19); }
 .MuiDialog-container { & {}
   .MuiDialog-paperWidthSm { width: 600px; }
   .MuiButton-text {color: #000000; }
}

// }

// COLUMN_MODAL //

.column-data-modal {
  & { } 

  .column-header { & { display: flex; justify-content: space-between; align-items: center; }
    h2 { font-size: 30px; color: #212121; }
}
  .search-container { & { display: flex; justify-content: space-between; align-items: center; }
    .MuiInput-root { border: 1px solid #BEBEBE; border-radius: 4px; height: 35px; }
    .MuiInput-input { padding-inline-start: 5px; }
    .MuiInputBase-fullWidth { width: 100%; }
    .MuiInput-underline::after { border-bottom: none; transition: none;  content: none; }
    .MuiInput-underline::before { border-bottom: none; transition: none;  content: none; }
    .MuiButton-root { color: #ffffff; background-color:  #122845; border-radius: 4px; height: 35px; width: 200px; }
  }

  .modal-fields { & { margin-top: 10px; border-radius: 4px; border: 1px solid #BEBEBE;}
    .MuiFormControl-root { width:  100%; } 
    .MuiFormControlLabel-root { display: flex; flex-direction: row-reverse; justify-content: space-between; border-bottom: 1px solid #E7EAEC; padding-left: 5px; height: 35px; margin-right: 0px; margin-left: 0px; padding-right: 10px; }
    .MuiTypography-root { color: #000000; font-size: 18px; font-family: $fontmontserrat; margin-left: 5px; }
  }
  .modal-btns {& { display:  flex; justify-content: space-around;}
    .save-btn { margin-top: 10px; width: 250px; border-radius: 4px; background-color: #122845; color: #ffffff;  } 
    .cancel-btn { margin-top: 10px; width: 250px; border-radius: 4px; background-color: #00000014; color: #1a1919; }
    .save-btn-disabled { margin-top: 10px; width: 250px; border-radius: 4px; background-color: #00000014; color: #ffffff; }
  }
}

.column-modal { top: 0; left: 0; right: 0; bottom: 0; z-index: -1; position: fixed; align-items: center;  justify-content: center; background-color: rgba(0, 0, 0, 0.5); -webkit-tap-highlight-color: transparent; }

.assessment-page-container {
  &{ padding-left: 24px;}
  .page-header {
    & { display: flex; align-items: center; justify-content: space-between; margin: 20px; }
    h1 { font-weight: 600;  font-size: 24px; font-weight: bold;  letter-spacing: 0px;color: #57606F;font-family: $fontmontserrat; }
    .create-btn { 
      & { background-color: #212A42; color: #ffffff; padding: 8px; font-size: 16px; font-family: $fontmontserrat;width: 107px;height: 40px; border-radius: 4px; font-weight: bold; }
    }
  }
  .MuiDataGrid-root .MuiDataGrid-columnHeaders{ background-color: #F1F1F1; color: #5B5B5B;  font-size: 15px;}
  .assesment-name {
    & {display: flex; flex-direction: column;}
    div { color: #0F131D;font-weight: 500;font-size: 14px;font-family: "Montserrat", sans-serif;}
    .editdetails{ display: flex; align-items: center; gap: 5px; }
  }
  .userinfo { color: #0F131D; font-size: 14px; }
  .view-btn {width: 67px;height: 32px;background-color: #122845; color: #fff; border-radius: 4px; font-size: 14px; cursor: pointer;display: flex; justify-content: center; align-items: center;}
  img {cursor: pointer; height: 30px;}
  .MuiDataGrid-iconSeparator { display: none;}
  .css-1kwdphh-MuiDataGrid-virtualScrollerContent {background-color: #ffffff;}
  .img-container {
    & { display: flex; flex-direction: column; margin: 4px 12px; gap: 2px;}
    div {color: #0F131D;font-weight: 400;font-size: 10px;font-family: "Montserrat", sans-serif; cursor: pointer;}
  }
  .border-none{border-bottom: none;}
  .assessment-img{width: 2%; padding-right: 8px;}
  .assesment-head{width:30%; padding-left: 28px;}
  .assesment-body{width:30%; padding-left: 0px;}
  .MuiTableCell-root .MuiTableCell-body .assesment-member { width: 10px; margin: 0; padding: 0; font-family: $fontmontserrat;}
  .assesment-date{ white-space: nowrap; padding-right: 0px; width: 10%;}
  .MuiTableHead-root{background-color: #F1F1F1;  cursor: pointer;position: sticky; z-index: 1; top:0;}
  .MuiTableContainer-root {width: 80vw;margin: 15px; height: 80vh; overflow: scroll;}
  .edit-row{display:flex;justify-content: end;align-items: center }
  .edit-container{display:flex;justify-content: space-between;align-items: center}
  .dot-icon:hover .edit-section{display: flex;justify-content: center;align-items: center;cursor: pointer; height: 10px; visibility: visible; }
  // .MuiTableRow-root:hover {}
  .edit-section{display: flex; position: relative; z-index: 3; background-color: #fbfbfb;}
  .MuiTableCell-body {height: 53px;padding-top: 1px;  padding-bottom: 1px;}
  .search-bar { & { position: relative; width: 100%; left: 13px; display: flex; gap: 10px;}
    input[type="search"].search-field::-webkit-search-cancel-button { -webkit-appearance: none; }
    .search-field { background-color: #FBF9F3; color: #000000; padding: 12px; font-size: 16px; border: 1px solid #CBCBCB; border-radius: 3px; margin: 0 0 15px 0; font-family: $fontmontserrat; width: 250px; }
    .clear-search-icon { position: absolute; left: 210px; bottom: 25px; cursor: pointer; color: #777777; height: 24px; width: 24px; font-size: 24px; }
    }
  .dot-icon{cursor: pointer;}
  .no-data-found{ display: flex; justify-content: center; align-items: center; height: 100%;}
  .arrow-updown{visibility: hidden;}
  .assesment-head:hover .arrow-updown-head{visibility: visible;}
  .assesment-leader, .assesment-member, .assesment-user{
    width: 8%; white-space: nowrap;}
  .assessment-row:hover{background-color: #f1f1f1;}

  .assessment-chip { display: inline-block; padding: 5px 12px; border-radius: 50px; margin-top: 11px; height: 13px; color: #fff; font-family: $fontmontserrat; cursor: pointer;text-align: center; font-size: 11px; text-transform: uppercase; font-weight: 500; }
  .assessment-team { background-color: #706aa1; }
  .assessment-referal{ background-color: #a1a16a; }
  .assessment-collection{ background-color: #a16a9e; }
  .assessment-multi{ background-color: #6a9ea1; }
  .assessment-simple{ background-color: #9e9e9e; }

  .assessment-stage-dev{background-color: #d32f2f;;}
  .assessment-stage-testing{background-color: #f57c00;}
  .assessment-stage-live{background-color: #2e7d32;}
  .assessment-stage-pause{background-color: #9e9e9e;}

  .assesmentTooltip-parent {  position: relative;}
  .assesmentTooltip {  font-size: 13px; position: absolute; right: 102%; font-weight: 600; max-width: 570px; display: block;  background-color: #f1f1f1;z-index: 3; }
  // .tooltip-bottom::before { content: ""; position: absolute; display: block;  width: 0px;  left: 88%;  bottom: 6px;  border: 10px solid transparent;  border-bottom: 0;  border-top: 10px solid #f0f0f0;  transform: translate(-50%, calc(100% + 5px));}
  .assesment-edit{width: 2%;}
}
.main .MuiTableCell-head {text-align: left; }
.MuiTab-textColorInherit.Mui-selected {font-weight:800!important; font-size:16px; }
.css-r11z79-MuiDataGrid-root .MuiDataGrid-cell--textCenter { justify-content: left !important; }


.assesment_leader { color: #212121; }
.heading-popup-leader {
  & { display: flex; align-items: flex-start; gap: 10px; margin-bottom: 30px; }
  p { color: #212121; font-size:16px; padding: 0px; margin: 0px; letter-spacing: 0; font-family: $fontmontserrat; font-weight: 500; }
} 
.header-leaderinfo {
  & { display: flex;justify-content:space-between; }
  img { height: 22px;cursor: pointer; }
}
.footer-leaderInfo {
  & {display: flex;justify-content: flex-end;align-items: center;gap:10px;}
  p {color: #E14949;font-size:16px;letter-spacing:0;font-weight:600;}
  .red-btn{background-color: #E14949;white-space: nowrap; color: #FFFFFF; font-weight: 600; font-family: $fontmontserrat;width:8%; }
  .red-btn:hover{background-color: #122845;}
}

.leaderInfo-popup {
  .heading-container {
  & { display: flex; justify-content: space-between; align-items:start; }
  .heading {
    & { letter-spacing: 1px; color: #000000; font-weight: 600; font-size: 20px; white-space: break-spaces; }
  }
}
    
.footer-container {
  & { display:flex;flex-direction:column;align-items: center; }
  .delete-btn{
    & { width:100%; min-height: 40px; background-color: #122845; border-radius: 5px; color: #fff; font-size: 16px; font-weight: 500; margin: 16px auto; cursor: pointer;font-family: $fontmontserrat; border: none; }
  } 
  .cancel-btn {
     & { width: 100%; min-height: 40px; background-color: #fff; border-radius: 5px; color: #122845; font-size: 16px; font-weight: 500; border: 1px solid #122845; cursor: pointer;font-family: $fontmontserrat; }
    }
  }  
}

.setup-assesment { 
  & { background-color: #FFFFFF; min-height: 87%; }
  h1 { font-family: $fontmontserrat; letter-spacing: 0px; color: #0F131D;  font-size: 20px; font-weight: 600; }
  h3 { font-family: $fontmontserrat; letter-spacing: 0px; color: #212121; font-size: 16px; font-weight: 400; padding-left: 20px; }
  .tab-btn { cursor: pointer; padding: 5px; margin-left: 10px; width: 120px; height: 40px; line-height: 1; border-radius: 4px; color: #FFF; border: 1px solid #122845; background-color: #122845; font-family: $fontmontserrat; margin-top: 15px; }
  .tab-btn :hover { cursor: pointer; padding: 5px; margin-left: 10px; width: 120px; height: 50px; border-radius: 4px; color: #FFF; border: 1px solid #122845; background-color: #122845; font-family: $fontmontserrat; margin-top: 15px; }

  .MuiTabs-flexContainer { border-bottom:  1px solid #F4F2EC; }
  .MuiTabs-fixed { height: 56px; }
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover { background-color:#122845 ;}
  .tab-title { text-transform: capitalize; font-family: $fontmontserrat; font-weight: 600; color: #5B5B5B;  }
  .draft-chip { font-size: 10px; background-color: gray; padding: 2px 8px; color: white; border-radius: 50px; display: flex; align-items: center; }
  .published-chip { font-size: 10px; background-color: #0AC74A; padding: 2px 8px; color: white; border-radius: 50px; display: flex; align-items: center; }
  .MuiTab-wrapper { display: flex; flex-direction: row-reverse; gap: 5px; }
  .users-table {
    .user-tab { display: flex; gap: 30px; justify-content: space-between; align-items: center; margin-left: 10px; }
    .columns {
      & { display: grid; grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); grid-gap: 20px; }
    }
    .columns-list-content {
      & { padding: 0px 10px; }
      .arrow { cursor: pointer; }
      .list-content { background-color: rgba(241, 241, 241, 1); border-radius: 8px; display: flex; justify-content: space-between; align-items: center; padding:10px; margin-bottom: 10px; width: auto; }
    }
    .save-btn { background-color: #FFF; border-radius: 4px; color: rgba(18, 40, 69, 1); font-size: 14px; width: 150px; padding: 5px ; font-weight: 400; font-family: $fontmontserrat; border: 1px solid rgba(18, 40, 69, 1); }
    .btn { background-color: rgba(18, 40, 69, 1); border-radius: 4px; color: #fff; font-size: 14px; width: 150px; padding: 5px ; font-weight: 400; font-family: $fontmontserrat; border: none; }
    .selected-coulmn-list {
     & { padding: 0px 10px;border-left: 1px solid #F4F2EC; }
     .columns-list-child {  background-color: rgb(241, 241, 241); border-radius: 8px; width: 700px;  padding: 0px 20px;   margin-bottom: 10px; width: auto; display: flex; align-items: center; justify-content: space-between;  }
    }
    .disabled {
      pointer-events: none;
      opacity: 0.5; 
    }
    .hidden {
      display: none !important;
    }
    .show{
      display: flex !important;
    }
    .list-label {
      & { font-family: $fontmontserrat; display: flex; flex-direction: column; }
      p { font-family: $fontmontserrat; letter-spacing: 0px; color: #676767; font-size: 13px; font-weight: 400; margin-top: 0px; padding-left: 20px; margin: 0 auto; }
      h3 { font-family: $fontmontserrat; letter-spacing: 0px; color: #212121; font-size: 16px; font-weight: 400; padding-left: 20px; margin: 0px 0px 2px; }
    }

  }
  .overview-table {
    .overview-contents {
      .chart-contents {
       & { display: grid; grid-template-columns: repeat(auto-fit, minmax(400px, 1fr)); grid-gap: 20px;  margin-bottom: 30px; }
        .chart-section {
         & { display: grid; grid-gap: 20px; height: 500px; background-color: #F1F1F1; position: relative; }
        }
        .label-container {
          & { display: flex; gap: 23px; margin-right: 10px; }
          input { width: 100%; font-family: $fontmontserrat; }
          .add-btn {cursor: pointer; width: 50px; border-radius: 4px; background-color: #122845; color: #ffffff; font-family: $fontmontserrat; border: none; font-size: 13px;  }
        }
        .chart-title { text-align: center; letter-spacing: 1px; color: #000000; font-weight: 600; font-size: 20px; font-family: $fontmontserrat; margin-bottom: 0px;  }
        .chart-section:hover .chart-title { visibility: hidden; }
        .chart-tools { position: absolute; top: 0; right: 0; visibility: hidden; z-index: 999; padding: 10px; display: flex; }
        .chart-section:hover .chart-tools {  visibility: visible;   }
        .delete-icon,.edit-icon {  display: none; }
        .chart-section:hover .delete-icon, .chart-section:hover .edit-icon {  display: inline-block; cursor: pointer;  }
        .delete-icon,.edit-icon {  margin-right: 5px; }
        .chart-svg { z-index: 1;}
        .border-green { border-top: 2px solid green; }
        .border-red { border-top: 2px solid red; }
      }
      .question-matrix-section {
        & { display: grid; grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); grid-gap: 20px; height:auto; background-color: #F1F1F1; }
      }
      .add-chart {  display: flex; justify-content: center;  align-items: center; gap: 20px; cursor: pointer; }
    }
      
  }
  
}

.add-piechart-section {
  & { width: 70vw;}
  h1 { font-family: $fontmontserrat; letter-spacing: 0px; color: #0F131D;  font-size: 24px; font-weight: 600; }
  .heading {
  & { letter-spacing: 1px; color: #000000; font-weight: 600; font-size: 20px; font-family: $fontmontserrat; }
  }
  img { cursor: pointer; height: 30px; }
  label { color: #212121; font-size: 17px; font-weight: 500; margin-bottom: 5px; font-family: $fontmontserrat; } 
  .text-box { width: auto; height: auto;  color: #000000;  padding: 10px;  font-size: 16px;  border:1px solid #BEBEBE;; background-color: #F1F1F1;  border-radius: 4px; font-family: "Montserrat", sans-serif; }
  .text-box-variable { width: 40%; height: auto;  color: #000000;  padding: 10px;  font-size: 16px;  border:1px solid #BEBEBE;; background-color: #F1F1F1;  border-radius: 4px; font-family: "Montserrat", sans-serif; }
  .color-box {  padding: 0px; margin: 0px;  border: none;   min-height: 50px;  border-radius: 4px;  width: 100%; cursor: pointer; position: relative; }
  .drop-icon { color: #fff; position: absolute; } 
  .box-container {  display: flex; justify-content: end; align-items: center; cursor: pointer; }
  .form-group{
    & { display: flex; flex-direction: column; margin-bottom: 15px; }
    .MuiOutlinedInput-root { height: 41px; }
  } 
  .text-container { display: flex; gap: 10px; align-items: center; }
  .chart-contents {
    & { display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 20px; padding: 30px; }
    .chart-section {
      & { display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 20px;   }
     .add-more-charts { display: flex; justify-content: center; align-items: center; min-height: 252px; }
     .chart-segment {
       & { padding: 20px; background-color: #F1F1F1; }
       .add-chart {  display: flex; justify-content: center;  align-items: center; flex-direction: column; cursor: pointer; }
      }
    }
  }
    .save-btn-chart { cursor: pointer; width: 100px;height: 30px; border-radius: 4px; background-color: #122845; color: #ffffff; font-family: $fontmontserrat; border: none; font-size: 15px;  } 
    .save-btn-chart:hover { cursor: pointer; width: 100px;height: 30px; border-radius: 4px; background-color: #122845;   }
    .save-btn-chart:disabled{color: #ffffff;} 

  .footer-btns { 
    & {  display: flex; justify-content: end; align-items: center; padding: 30px; gap: 20px; }
    .save-btn { cursor: pointer; width: 250px; border-radius: 4px; background-color: #122845; color: #ffffff; font-family: $fontmontserrat; border: none; font-size: 17px;  } 
    .cancel-btn { cursor: pointer; width: 250px; border-radius: 4px; background-color: #FFF; border: 1px solid #122845; color: #122845; font-family: $fontmontserrat; font-size: 17px; }

  }
}


.order-no-and-drag-section-name-icon {  display: flex; gap: 20px; align-items: center; }
.drag-section { display: flex; align-items: center; }
.delIcon { display: flex;  justify-content: end;  margin-right: 24px; cursor: pointer; gap: 8px; padding-left: 16px; }
.section-name { font-family: $fontmontserrat; letter-spacing: 0px; color: #212121; font-size: 16px; font-weight: 400; }

.color-selection-container {
  & { width: auto; }
  .heading-container { 
    & { display: flex; justify-content: space-between; align-items: center; margin-bottom: 15px; }
    h3 { margin: 0px; padding: 0px; font-size: 16px; font-family: $fontmontserrat; }
  }
  label {
    & { letter-spacing: 0px; color: rgba(33, 33, 33, 1); font-size: 17px; font-weight:500; margin-bottom: 4px; font-family: $fontmontserrat; }
  }
  .create-btn { cursor: pointer; width: 100%; height: 50px; margin-top: 20px; border-radius: 4px; background-color: #122845;  color: #FFF; font-family: $fontmontserrat; font-size: 17px; border: none; }
  .sketch-picker { width: 100% !important; max-width: 400px; max-height: 700px; }

}


.add-chart-container {
  & {  width: auto; }
  .heading-container {
    & { display: flex; justify-content: space-between; align-items: center; height: 50px; margin-bottom: 10px;}
    img { cursor: pointer; }
    h3 { font-weight: 600;  font-size: 30px;   letter-spacing: 0px; color: #212121; font-family: $fontmontserrat; }
  }
  .items{
    & {  margin-top: 10px; }
    .label {
      & { letter-spacing: 0px; color: rgba(33, 33, 33, 1); font-size: 17px; font-weight:500; margin-bottom: 4px; font-family: $fontmontserrat; }
    }
    .MuiFormGroup-root { flex-direction: row; gap: 30px; }
    .size-chart-sec { gap : 65px}
    .MuiRadio-colorSecondary.Mui-checked { color: rgba(18, 40, 69, 1) }
    .MuiTypography-body1 { font-size: 16px; font-family: $fontmontserrat; font-weight: 400; letter-spacing: 0px; }
    .all-fields { color: #212121; }
    // .customize-export { color: #e5e5e5; }
    .MuiInputBase-fullWidth { width: 64%;  height: 50px; }
    .MuiTextField-root { margin-bottom: 0px;}
  }
  .add-btn { width: 500px; margin-top: 20px; height: 50px; border-radius: 4px; color: #FFF; border: none; background: #122845; font-family: $fontmontserrat; font-size: 17px; cursor: pointer; }
  .add-btn:hover {   background-color: #122845; color: #FFF;  width: 500px;}
}
  
.add-bargraph-section {
  & { width: 70vw;}
  h1 { font-family: $fontmontserrat; letter-spacing: 0px; color: #0F131D;  font-size: 24px; font-weight: 600; }
  .heading {
  & { letter-spacing: 1px; color: #000000; font-weight: 600; font-size: 20px; font-family: $fontmontserrat; }
  }
  img { cursor: pointer; height: 30px; }
  label { color: #212121; font-size: 17px; font-weight: 500; margin-bottom: 5px; font-family: $fontmontserrat; } 
  .text-box { width: auto; height: auto;  color: #000000;  padding: 10px;  font-size: 16px;  border:1px solid #BEBEBE;; background-color: #F1F1F1;  border-radius: 4px; font-family: "Montserrat", sans-serif; }
  .color-box {  padding: 0px; margin: 0px;  border: none;   min-height: 50px;  border-radius: 4px;  width: 100%; cursor: pointer; position: relative; }
  .drop-icon { color: #fff; position: absolute; } 
  .box-container {  display: flex; justify-content: end; align-items: center; cursor: pointer; }
  .form-group{
    & { display: flex; flex-direction: column; margin-bottom: 15px; }
  } 
  .bar-contents {
    & { display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 20px; padding: 30px; }
    .bar-section {
      & { display: grid; grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); grid-gap: 20px;   }
     .add-more-bargraphs { display: flex; justify-content: center; align-items: center; min-height: 252px; }
     .bar-segments {
       & { padding: 20px; background-color: #F1F1F1; }
       .add-graph {  display: flex; justify-content: center;  align-items: center; flex-direction: column; cursor: pointer; }
      }
    }
  }
  .footer-btns { 
    & {  display: flex; justify-content: end; align-items: center; padding: 30px; gap: 20px; }
    .save-btn { cursor: pointer; width: 250px; border-radius: 4px; background-color: #122845; color: #ffffff; font-family: $fontmontserrat; border: none; font-size: 17px;  } 
    .cancel-btn { cursor: pointer; width: 250px; border-radius: 4px; background-color: #FFF; border: 1px solid #122845; color: #122845; font-family: $fontmontserrat; font-size: 17px; }
  }
  .items{
    & { padding: 0 30px; }
    .label {
      & { letter-spacing: 0px; color: rgba(33, 33, 33, 1); font-size: 17px; font-weight:500; margin-bottom: 4px; font-family: $fontmontserrat; }
    }
    .MuiFormGroup-root { flex-direction: row; gap: 30px; }
    .MuiRadio-colorSecondary.Mui-checked { color: rgba(18, 40, 69, 1) }
    .MuiTypography-body1 { font-size: 16px; color:#212121; font-family: $fontmontserrat; font-weight: 400; letter-spacing: 0px; }
  }

}


.question-matrix-contents{
  .contents {
    & { display: grid; grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); grid-gap: 20px;  max-width: 100%;  margin: 0 auto;    }
  }
  .contents:nth-child(even) {
    background-color: #F1F1F1; 
  }
  .contents h3 {
    color: #0F131D; font-size: 18px ; font-family: $fontmontserrat; font-weight: 600;
  }
}


.export-csv-modal {
  .items .label {  font-size: 16px; margin-top: 20px; }
  .items .MuiTypography-body1 { font-weight: 300; }
  .add-btn { margin-top: 30px; }

  .columns-container {
    .columns {
      & { display: grid; grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); grid-gap: 20px; }
    }
    .columns-list-content {
      & { padding: 0px 10px; }
      .arrow { cursor: pointer; }
      .list-content { background-color: rgba(241, 241, 241, 1); border-radius: 8px; display: flex; justify-content: space-between; align-items: center; padding:10px; margin-bottom: 10px; width: auto; }
    }
    .save-btn { background-color: #FFF; border-radius: 4px; color: rgba(18, 40, 69, 1); font-size: 14px; width: 150px; padding: 5px ; font-weight: 400; font-family: $fontmontserrat; border: 1px solid rgba(18, 40, 69, 1); }
    .btn { background-color: rgba(18, 40, 69, 1); border-radius: 4px; color: #fff; font-size: 14px; width: 150px; padding: 5px ; font-weight: 400; font-family: $fontmontserrat; border: none; }
    .selected-coulmn-list {
     & { padding: 0px 10px;border-left: 1px solid #F4F2EC; }
     .columns-list-child {  background-color: rgb(241, 241, 241); border-radius: 8px; width: 700px;  padding: 0px 20px;   margin-bottom: 10px; width: auto; display: flex; align-items: center; justify-content: space-between;  }
    }
    .disabled { pointer-events: none; opacity: 0.5;  }
    .hidden { display: none !important; }
    .show { display: flex !important; }
    .list-label {
      & { font-family: $fontmontserrat; display: flex; flex-direction: column; }
      p { font-family: $fontmontserrat; letter-spacing: 0px; color: #676767; font-size: 13px; font-weight: 400; margin-top: 0px; padding-left: 20px; margin: 0 auto; }
      h3 { font-family: $fontmontserrat; letter-spacing: 0px; color: #212121; font-size: 16px; font-weight: 400; padding-left: 20px; margin: 0px 0px 2px; }
    }
    .delIcon { margin-right: 0px;}
  }
  .btn-container { display: flex; justify-content: center; }

  .columns-list-content { max-height: 400px; overflow-y: scroll; }
}

.customise-csv { width: 960px; }

.export-csv-thankyou-modal{
  & { text-align: center; }
  h3 { font-family: $fontmontserrat; letter-spacing: 0px; color: #0F131D;  font-size: 36px; font-weight: 600; margin: 0px; }
  p { font-family: $fontmontserrat; letter-spacing: 0px; color: #0F131D;  font-size: 22px; font-weight: 400; margin-top: 10px; }

}

.upload-image-modal-container {
  & { height: 80vh; width: 60vw; display: flex; flex-direction: column; align-items: center; justify-content: space-between; }

  .title { 
    & { height: 50px; width: 100%; display: flex; align-items: center; justify-content: space-between; padding: 0 15px; }
    h2 { margin: 0; color: #0F131D; }
    .MuiSvgIcon-root { color: #0F131D; cursor: pointer;}
  }

  .crop {
    & {  height: calc(100% - 130px); padding: 10px 0; position: relative; width: 100%; display: flex; flex-direction: column; align-items: center; justify-content: space-evenly; }
    .d-n-d { 
      & { height: 80%; width: 90%; border: 2px dashed #929292; border-radius: 10px; display: flex; align-items: center; justify-content: center;}
    }
  }

  .crop-btns {
    & { position: relative; height: 80px; width: 100%; display: flex; align-items: center; justify-content: space-between; }
    .tips {
      & { position: absolute; bottom: -10px; left: 0px; display: flex; align-items: center; justify-content: flex-start; }
      &>span {
        & { color: #0F131D; font-size: 14px; }
      }
    }
  }
  .btn-primary {
    & { display: flex; align-items: center; justify-content: center; gap: 10px; min-width: 80px; font-family: inherit; font-size: 16px; font-weight: 500; background: #0F131D; border: 2px solid transparent; color: #fff; box-shadow: 0 0 5px #bdbdbd79; border-radius: 5px; padding: 6px 8px; transition: 250ms ease-in-out; cursor: pointer; }
    &:hover { filter: drop-shadow(0 0 5px #00000030); }
    &:disabled {  background: #afafaf !important; }
  } 
}

.mce-content-body h3 { margin-block-start: 0; margin-block-end: 0; }
.mce-content-body p { margin-block-start: 0; margin-block-end: 0; }
button {border: 0px; }

li.Mui-expanded.Mui-selected.MuiTreeItem-root{border: 1px solid #E6E6E6;border-radius: 4px;}

.MuiCollapse-wrapperInner>li{border: none;}

.MuiCollapse-wrapperInner>li{
  border: none;
}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {background-color: #fff;}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {background-color: #fff !important;}
.MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {background-color: #fff !important;}
.MuiTreeItem-content {padding-left: auto;}

.custom-text-editor {
  & { position: relative; }
  .custom-editor-tools { gap: 0.8rem; padding-left: 1rem !important; padding-right: 1rem !important; background-color: #fff; border-radius: 4px; padding: .5rem; position: absolute; z-index: 10; }
}

.modal .create-new .style-modal {
  & { height: 450px; width: 450px; }
}