
//Header Card
.cards-container { display: flex; gap: 32px; overflow-x: auto; scrollbar-width: none; -ms-overflow-style: none; margin-bottom: -16px; padding-bottom: 16px; flex-wrap: nowrap;padding-left: 32px; padding-right: 32px;
    &::-webkit-scrollbar { display: none; } 
    .card { display: flex; justify-content: space-between; flex-direction: column; box-sizing: border-box; background: white; padding: 16px; border-radius: 12px; box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); flex-shrink: 0; width: 265px; height: 156px;
        .card-header { display: flex; flex-direction: row; justify-content: space-between;
            .card-title { font-size: 20px; font-weight: 500; color: #394554; margin: 0; }
            .extra-info { box-sizing: border-box; display: flex; align-items: flex-start; justify-content: space-between; background: white; padding: 8px; border-radius: 8px; border: 1px solid #E5E7EB; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08); font-size: 12px; font-weight: 400; color: #394554; width: 99px; height: 50px; flex-direction: column;
                span { font-size: 11px; font-weight: 400; color: #394554; }
                strong { font-size: 12px; font-weight: 900; color: #394554; text-align: left; }
            }
        }
        .card-body{
            &{display: flex;align-items: end;justify-content: space-between;}
            .content-section{
                &{display: flex;flex-direction: column;}
                .card-content { display: flex; align-items: center;
                    .card-value { font-size: 34px; font-weight: 700; margin-right: 10px; }
                    .card-change { font-size: 12px; padding: 4px 8px; border-radius: 5px; font-weight: 700;
                        &.up { background: #D6E9D5; color: #31922C; }
                        &.down { background: #F8E7E9; color: #B6111F; }
                    }
                }
                .card-subtext { font-size: 12px; font-weight: 400; color: #9B9B9B; margin: 0px; margin-top: 5px; }
            }

        }
    }
}


//Main
.qf-main{background: white;
    .header-container{padding: 16px 0px 15px 32px; background-color: #F4F2EC; color: #57606f;
        .qf-title{
            font-size: 26px; font-weight: 700;  font-family: $fontbutler; margin: 0px;
        }
        .qf-subtitle{
            font-size: 18px; margin: 5px auto;font-family: $mainfont, sans-serif;font-weight: 500;
            a{
                color: #57606f;
            }
        }
    }

    .client-info-card {
        display: flex;
        flex-direction: row;
        gap: 16px;
        // width: 100%;
        justify-content: space-between;
        border-radius: 12px;
        padding: 32px;
      
        .client-container {
          display: flex;
          justify-content: space-between;
        //   align-items: center;
      
          .client-details {
            h2 {
                font-style: $fontmontserrat;
              font-size: 32px;
              font-weight: 600;
              color: #394554;
              margin: 0px;
            }
            p {
              font-size: 16px;
              color: #394554;
              margin: 4px 0;
            }
          }
      
          

          
        }
      
        .stats-container{display: flex;align-items: flex-start;justify-content: flex-end;gap:24px;
            .stats-table {
                box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid #ddd;
            
                .table-header {
                  display: flex;
                  background: #1e293b;
                  color: white;
                  font-weight: 500;
                  padding: 8px 24px;
                  gap: 57px;
            
                  .cell {
                    text-align: center;
                    font-size: 16px;
                    font-weight: 500;
                  }
                }
            
                .table-row {
                    gap: 57px;
                  display: flex;
                  background: white;
                  padding: 13px 24px;
                 
            
                  &:not(:last-child) {
                    border-bottom: 1px solid #ddd;
                  }
            
                  .cell {
                    flex: 1;
                    text-align: left;
                    font-size: 18px;
                    font-weight: 600;
                    color: #1e293b;
                  }
                }
              }
            .close-btn-body{
                height: 100%;
                display: flex;
                align-items: flex-start;

                .close-btn {
                    background: none;
                    border: none;
                    width: 21px;
                    height: 21px;
                    cursor: pointer;
                    color: #394554;
                    padding: 0px;
                  }
              }
        }
      }
      
      
    .MuiTab-root {
        text-transform: none !important;
        font-size: 18px;
        width: auto;
        margin-right: 32px;
        line-height: 0;
        padding-left: 0px !important;
    }
    .MuiTab-textColorInherit.Mui-selected{
        font-weight: 600 !important;
        font-size: 18px;
    }
}

//Dashboard
.dashboard { background: white; font-family: $fontmontserrat;
    .header-container{padding: 16px 0px 15px 32px; background-color: #F4F2EC; color: #57606f;
        .qf-title{
            font-size: 26px; font-weight: 700;  font-family: $fontbutler; margin: 0px;
        }
        .qf-subtitle{
            font-size: 18px; margin: 5px auto;font-family: $mainfont, sans-serif;font-weight: 500;
            a{
                color: #57606f;
            }
        }
    }
    .main-body { padding: 32px 0px;
        .charts-container {
            display: flex;
            flex-direction: row;
            gap: 32px;
            align-items: center;
            margin-top: 32px;
            padding-left: 32px; padding-right: 32px;

            .chart-card {
              width: 562px;
              height: 369px;
              background: white;
              box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
              border-radius: 12px;
              padding: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            
              .chart-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
            
                h3 {
                  font-size: 1.2rem;
                  font-weight: 600;
                  color: #374151;
                }
            
                select {
                  border: 1px solid #ddd;
                  padding: 4px 8px;
                  border-radius: 6px;
                  color: #4a4a4a;
                }
              }
            }
          }    
    }
}

//Consultants
.consultants{background: white;
    .header-container{padding: 16px 0px 15px 32px; background-color: #F4F2EC; color: #57606f;
        .qf-title{
            font-size: 26px; font-weight: 700;  font-family: $fontbutler; margin: 0px;
        }
        .qf-subtitle{
            font-size: 18px; margin: 5px auto;font-family: $mainfont, sans-serif;font-weight: 500;
            a{
                color: #57606f;
            }
        }
    }
    .main-body { padding: 32px 0px;
        .css-seqwlu{padding: 32px !important; width: auto !important;

        }
    }
}

//Clients
.clients{background: white;
  .header-container{padding: 16px 0px 15px 32px; background-color: #F4F2EC; color: #57606f;
      .qf-title{
          font-size: 26px; font-weight: 700;  font-family: $fontbutler; margin: 0px;
      }
      .qf-subtitle{
          font-size: 18px; margin: 5px auto;font-family: $mainfont, sans-serif;font-weight: 500;
          a{
              color: #57606f;
          }
      }
  }
  .main-body { padding: 32px 0px;
      .css-seqwlu{padding: 32px !important; width: auto !important;

      }
  }
}
//Token
.token{background: white;
  .header-container{padding: 16px 0px 15px 32px; background-color: #F4F2EC; color: #57606f;
      .qf-title{
          font-size: 26px; font-weight: 700;  font-family: $fontbutler; margin: 0px;
      }
      .qf-subtitle{
          font-size: 18px; margin: 5px auto;font-family: $mainfont, sans-serif;font-weight: 500;
          a{
              color: #57606f;
          }
      }
  }
  .main-body { padding: 32px 0px;
      .css-seqwlu{padding: 32px !important; width: auto !important;

      }
  }
}

//Assessments
.assessments{background: white;
    .header-container{padding: 16px 0px 15px 32px; background-color: #F4F2EC; color: #57606f;
        .qf-title{
            font-size: 26px; font-weight: 700;  font-family: $fontbutler; margin: 0px;
        }
        .qf-subtitle{
            font-size: 18px; margin: 5px auto;font-family: $mainfont, sans-serif;font-weight: 500;
            a{
                color: #57606f;
            }
        }
    }
    .main-body { padding: 32px 0px;
        .css-seqwlu{padding: 32px !important; width: auto !important;
  
        }
    }
  }

//ViewReport
.view-reports{background: white;
    .header-container{padding: 16px 0px 15px 32px; background-color: #F4F2EC; color: #57606f;
        .qf-title{
            font-size: 26px; font-weight: 700;  font-family: $fontbutler; margin: 0px;
        }
        .qf-subtitle{
            font-size: 18px; margin: 5px auto;font-family: $mainfont, sans-serif;font-weight: 500;
            a{
                color: #57606f;
            }
        }
    }
    .main-body { padding: 32px 0px;
        .css-seqwlu{padding: 32px !important; width: auto !important;
  
        }
    }
  }

//ClientsDetailsModal
.modal-content {
    box-sizing: border-box;
    width: calc(1009px - 64px);
    height: calc(343px - 64px); background: #fff; border-radius: 16px;

    .close-btn {
        position: absolute; top: 16px; right: 16px; border: none;background-color: white;
        padding: 5px 10px; font-size: 30px; cursor: pointer; border-radius: 4px;
        &:hover { background: #ccc; }
    }

    .modal-title { font-size: 30px; font-weight: 600; color: #394554; margin: 0; margin-bottom: 32px; }

    .modal-grid {
        display: grid; grid-template-columns: repeat(3, 1fr); gap: 24px;
        
        div { @extend %grid-item; }
    }
}

%grid-item {
    .label { font-size: 14px; color: #394554; font-weight: 400; margin: 0px;}
    .value { font-size: 24px; font-weight: 600; color: #394554;margin: 0px; }
}

// RatioChart

.ratio-chart-container{
    &{padding: 32px; gap: 32px;display: flex; align-items: center; flex-direction: row;}
    .chart-container {
        background: #fff; padding: 20px; border-radius: 12px; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); text-align: center; width: 364px; height: 387px; display: flex; flex-direction: column; justify-content: space-between; align-items: center;
      
        .title {
          font-size: 20px; font-weight: 500; color: #394554; padding: 4px; margin: 0px; text-align: left;;
        }
      
        .tooltip {
          background: #fff; padding: 5px 10px; border-radius: 4px; box-shadow: 0 1px 6px rgba(0, 0, 0, 0.15); font-size: 14px; color: #333;
        }
      
        .legend {
          display: flex; justify-content: flex-start; gap: 15px; margin-top: 10px; width:100%;
      
          .item {
            display: flex; align-items: center; font-size: 14px; color: #555;
      
            .color-box {
              width: 12px; height: 12px; border-radius: 3px; margin-right: 5px;
      
              &.consultants { background: #212A42; }
              &.clients { background: #4D5568; }
            }
          }
        }
      }
}
  





  